import React, { useContext, useEffect, useState } from 'react';
import { AppBar, Button, CircularProgress, Dialog, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, IconButton, makeStyles, TextField, Toolbar, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Close, Delete, Edit, ExpandMore } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone';
import MUIDataTable from 'mui-datatables';
import { usePaperStyles } from '../../Styles/StylePaper';
import PersistantData from '../../Services/PersistantData';
import AppContext from '../../Services/AppContext';
import { useSnackbar } from 'notistack';
import { empty, no_image, pdf_image, req_appcontent_einfo } from '../../Helper/Constants';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, { axiosDefaultConfig, axiosPutConfig, axiosURIencode } from '../../Services/API_Service';
import { arrayBufferToBase64, de } from '../../Helper/Helper';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import MediaBox from '../../Components/MediaBox';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
	card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	textleft: {
		textAlign: "left"
	},
	dropzone: {
		width: '50%',
		borderRadius: 5
	},
	dropzoneText: {
		color: grey[600]
	},
	adverPaper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: theme.spacing(1.5)
	},
	adverView: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1)
	},
	textarea: {
		width: '100%'
	},
	dateBtn: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	statusBtn: {
		marginLeft: 30,
		width: 150
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	}
}));

export default function AppContent() {
	const tableHead = [
		{
			name: "id",
			label: "NO",
			options: {
				display: true,
				customBodyRender: (value, tableMeta) => tableMeta.rowIndex + 1
			}
		},
		{
			name: "title",
			label: "Title",
			options: {
				display: true
			}
		},
		{
			name: "announcement_date",
			label: "Publish Date",
			options: {
				display: true,
				customBodyRender: (value) => {
					if (value && value !== ''){
						return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
					} else {
						return ('-');
					}
				}
			}
		},
		{
			name: "description",
			label: "Description",
			options: {
				display: true
			}
		},
		{
			name: "created_date",
			label: "Created Date",
			options: {
				display: true,
				customBodyRender: (value) => moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
			}
		},
		{
			name: "updated_date",
			label: "Updated Date",
			options: {
				display: true,
				customBodyRender: (value) => moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
			}
		},
		{
			name: "updated_by",
			label: "Updated By",
			options: {
				display: true
			}
		},
		{
			name: "action",
			label: "Action",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<>
							<IconButton
								// edge={'end'}
								// color={'primary'}
								onClick={() => updateData(tableMeta.rowData[0])}>
								<Edit/>
							</IconButton>
							<IconButton
								// edge={'end'}
								// color={'primary'}
								onClick={() => deleteData(tableMeta.rowData[0])}>
								<Delete/>
							</IconButton>
						</>
					);
				}
			}
		}
	];
	const paperStyles = usePaperStyles();
	const classes = useStyles();
	const singleton = PersistantData.getInstance();
	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();

	const [isUploading, setUploading] = useState(false);
	const [eInfoData, setEInfoData] = useState([]);

	const [dateFrom, setDateFrom] = useState(new Date());
	const [announcementname, setAnnouncementName] = useState(empty);
	const [announcementdesc, setAnnouncementDesc] = useState(empty);
	const [announcementFile, setAnnouncementFile] = useState(null);

	const [showDetails, onShowDetails] = useState(false);
	const [eInfoDetails, setEInfoDetails] = useState({});

	const [dropzoneKey, setDropzoneKey] = useState(0);

	const [openUploadDialog, setOpenUploadDialog] = useState(false);

	const handleOpenUploadDialog = () => setOpenUploadDialog(true);
	const handleCloseUploadDialog = () => setOpenUploadDialog(false);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const redefineHeaders = (columns) => {
		return columns;
	}
	const tableHeader = redefineHeaders(tableHead);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			loadAnnouncements();
		}
	}, [gState.client]);

	const resetData = () => {
		setDateFrom(new Date());
		setAnnouncementName(empty);
		setAnnouncementDesc(empty);
		setAnnouncementFile(null);
		setDropzoneKey((prevKey) => prevKey + 1);
	}

	const loadAnnouncements = () => {
		API_Service.get(req_appcontent_einfo, axiosDefaultConfig(gState.client)).then(({data}) => {
			var {result, extraData, message} = data;
			result = JString(result).toNumber();
			if (result === 0) {
				snack(message, 'warning');
			} else {
				setEInfoData(extraData);
				snack(message);
			}
		});
	}

	const updateData = (data) => {
		for (let x = 0; x < eInfoData.length; x++) {
			if (eInfoData[x]['id'] === data) {
				setEInfoDetails(eInfoData[x]);
				onShowDetails(true);
			}
		}
	}

	const deleteData = (data) => {
		let selected_data;
		for (let x = 0; x < eInfoData.length; x++) {
			if (eInfoData[x]['id'] === data) {
				selected_data = eInfoData[x];
			}
		}

		if (selected_data) {
			updateEInfo(selected_data, 'delete');	
		}
	}

	const closeDialog = () => {
		setEInfoDetails({});
		onShowDetails(false);
	}

	const updateEInfo = (data, action) => {
		var params = data;
		if (action === 'delete') {
			params.delete = 1;
		}
		params = axiosURIencode(params);
		API_Service.put(req_appcontent_einfo, params, axiosPutConfig(gState.client)).then(({data}) => {
			var {result, message} = data;
			result = JString(result).toNumber();
			if (result === 0) {
				snack(message, 'warning');
			} else {
				snack(message);
				loadAnnouncements();
				resetData();
				closeDialog();
			}
		});
	}

	const onUpdateEInfo = () => {
		updateEInfo(eInfoDetails, 'update');
	}

	const deleteImage = (index) => {
		const currentAttachments = JSON.parse(eInfoDetails.attachment || '[]');
		const updatedAttachments = currentAttachments.filter((_, i) => i !== index);
		setEInfoDetails((prevDetails) => ({
			...prevDetails,
			attachment: JSON.stringify(updatedAttachments)
		}));

		const updatedDetails = {
			...eInfoDetails,
			attachment: JSON.stringify(updatedAttachments),
		};
		// updateEInfo(updatedDetails, 'update');
	}

	const onChangeAnnouncementDesc = props => event => {
		var text = event.target.value;
		setAnnouncementDesc(text);
	}

	const onAnnouncementUpload = (files) => {
		const fileArray = [];
		if (files.length > 0) {
			files.forEach((file) => {
				const reader = new FileReader();
		  
				reader.onabort = () => console.error('File reading was aborted');
				reader.onerror = () => console.error('File reading has failed');
				reader.onload = () => {
					const binaryStr = reader.result;
					fileArray.push({
						file: arrayBufferToBase64(binaryStr),
						name: file.name,
						type: file.type
					});
				};
				
				reader.readAsArrayBuffer(file);
			});

			setAnnouncementFile(fileArray);
		} else {
			setAnnouncementFile(null);
		}
	}

	const onAnnouncementUploadDetails = async (files) => {
		const fileArray = [];
	  
		if (files.length > 0) {
			const filePromises = files.map((file) => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
	  
					reader.onabort = () => reject(new Error('File reading was aborted'));
					reader.onerror = () => reject(new Error('File reading has failed'));
					reader.onload = () => {
						const binaryStr = reader.result;
						resolve({
							file: arrayBufferToBase64(binaryStr),
							name: file.name,
							type: file.type
						});
					};
	  
					reader.readAsArrayBuffer(file);
				});
			});
	  
			try {
				const processedFiles = await Promise.all(filePromises);
				fileArray.push(...processedFiles);
	  
				setEInfoDetails((prevDetails) => ({
					...prevDetails,
					newFile: JSON.stringify(fileArray),
				}));
			} catch (error) {
				console.error('Error processing files:', error);
			}
		} else {
			setEInfoDetails((prevDetails) => ({
				...prevDetails,
				newFile: null,
			}));
		}
	};

	const onSaveEInfo = () => {
		setUploading(true);

		var dtFrom = moment(dateFrom).format('YYYY-MM-DD');
		var formdata = new FormData();
		formdata.append('title', announcementname);
		formdata.append('description', announcementdesc);
		formdata.append('publish_date', dtFrom);
		formdata.append('file', JSON.stringify(announcementFile));

		API_Service.post(req_appcontent_einfo, formdata, axiosDefaultConfig(gState.client)).then(({data}) => {
			var {result} = data;
			if (parseInt(result) == 1) {
				snack(language.announcement_success);
				resetData();
				loadAnnouncements();
			} else {
				snack(language.announcement_fail, 'error');
			}
			setUploading(false);
			setOpenUploadDialog(false);
		}).catch((err) => {
			setUploading(false);
		});
	}

	const CustomToolbar = () => {
        return (
			<Tooltip title={'Add E-Info'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={handleOpenUploadDialog} 
					>
					<AddIcon/>
				</IconButton>
			</Tooltip>
        );
    };

	const isAnnouncementname = announcementFile !== null && JString(announcementname).isValid();

	return (
		<div>
			<div style={{height: 10}}/>
			<MUIDataTable
				data={eInfoData}
				columns={tableHeader}
				options={{
					search: true,
					print: false,
					filter: false,
					download: false,
					selectableRows: 'none',
					rowsPerPage: 25,
					rowsPerPageOptions: [25, 40, 65, 100],
					responsive: 'simple',
					customToolbar: CustomToolbar
				}}
			/>
			
			<Dialog open={openUploadDialog} onClose={handleCloseUploadDialog} fullWidth maxWidth="md">
				<AppBar position="static">
					<Toolbar>
						<Typography variant="h6" style={{ flexGrow: 1 }}>
							Upload a new E-Info
						</Typography>
						<IconButton edge="end" color="inherit" onClick={handleCloseUploadDialog}>
							<Close />
						</IconButton>
					</Toolbar>
				</AppBar>

				<div className={classes.adverPaper} style={{ padding: 20 }}>
					<div style={{ width: '50%', paddingRight: 10 }}>
						<TextField
							style={{ width: '100%' }}
							label="Title/Subject"
							value={announcementname}
							onChange={(e) => setAnnouncementName(e.target.value)}
						/>
						<div style={{ height: 10 }} />
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DatePicker
								className={classes.dateBtn}
								label="Publish Date"
								inputVariant="outlined"
								value={dateFrom}
								margin="dense"
								onChange={setDateFrom}
							/>
						</MuiPickersUtilsProvider>
						<div style={{ height: 10 }} />
						<TextField
							id="outlined-multiline-static"
							label="Description"
							multiline
							rows={4}
							variant="outlined"
							className={classes.textarea}
							value={announcementdesc}
							onChange={onChangeAnnouncementDesc('alert_content')}
						/>
						<div style={{ height: 10 }} />
						<Button
							variant="contained"
							color="primary"
							onClick={onSaveEInfo}
							disableElevation
							disabled={!isAnnouncementname || isUploading}
							endIcon={isUploading ? <CircularProgress size={20} /> : null}
						>
							Save
						</Button>
					</div>
					<DropzoneArea
						key={dropzoneKey}
						dropzoneClass={classes.dropzone}
						dropzoneParagraphClass={classes.dropzoneText}
						onChange={onAnnouncementUpload}
						acceptedFiles={['image/jpeg', 'image/png', 'image/jpg', 'application/pdf']}
						clearOnUnmount
						filesLimit={Infinity}
					/>
				</div>
			</Dialog>

			<Dialog fullScreen open={showDetails} onClose={() => closeDialog()}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={() => closeDialog()} aria-label="close">
							<Close/>
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							E-Info Details
						</Typography>
					</Toolbar>
				</AppBar>
				<div className={classes.adverPaper} style={{paddingTop: 100}}>
					<div style={{width: '50%', paddingRight: 10}}>
						<TextField
							style={{width: '100%', height: 50}}
							label={'Title/Subject'}
							value={eInfoDetails?.title}
							onChange={(e) => setEInfoDetails((prevDetails) => ({
								...prevDetails,
								title: e.target.value,
							}))}
						/>
						<div style={{height: 10}}/>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DatePicker
								className={classes.dateBtn}
								label={'Publish Date'}
								inputVariant={'outlined'}
								value={new Date(eInfoDetails?.announcement_date)}
								margin={'dense'}
								onChange={(e) => setEInfoDetails((prevDetails) => ({
									...prevDetails,
									announcement_date: moment(e).format('YYYY-MM-DD')
								}))}/>
						</MuiPickersUtilsProvider>
						<div style={{height: 10}}/>
						<TextField
							id="outlined-multiline-static"
							label={'Description'}
							onChange={(e) => setEInfoDetails((prevDetails) => ({
								...prevDetails,
								description: e.target.value,
							}))}
							multiline
							rows={4}
							variant="outlined"
							className={classes.textarea}
							value={eInfoDetails?.description}
						/>
						<div style={{height: 10}}/>
						<Button
							variant={'contained'}
							onClick={() => {
								onUpdateEInfo()
							}}
							size="small"
							endIcon={isUploading ? <CircularProgress size={20}/> : null}
							color={'primary'}>
							{language.update}
						</Button>
					</div>
					<DropzoneArea
						dropzoneClass={classes.dropzone}
						dropzoneParagraphClass={classes.dropzoneText}
						onChange={(files) => {
							onAnnouncementUploadDetails(files)
						}}
						acceptedFiles={['image/jpeg', 'image/png', 'image/jpg', 'application/pdf']}
						clearOnUnmount={true}
					/>
				</div>
				<div className={paperStyles.paperViewWOspace}>
					{
						eInfoDetails?.attachment && JSON.parse(eInfoDetails?.attachment).map((item, index) => {
							return (
								<MediaBox
									key={String(index)}
									image={item.url || no_image}
									title={item.name}
									description={null}
									secondaryBtn={language.delete}
									viewBtn={'View'}
									secondaryBtnAction={() => deleteImage(index)}
									viewBtnAction={() => window.open(item.url, '_blank')}
									Component={item.name?.endsWith(".pdf") ?
										() => <img src={pdf_image} alt="content" style={{ width: "100%", aspectRatio: "1 / 1", padding: 70 }} />
										: null
									}
								/>
							)
						})
					}
				</div>
			</Dialog>
		</div>
	)
}
