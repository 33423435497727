import JString from "@easytech-international-sdn-bhd/jstring";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Tooltip, IconButton, Menu, MenuItem, CircularProgress, TextField, InputAdornment, Typography, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Services/AppContext";
import { grey, red } from "@material-ui/core/colors";
import PersistantData from "../../Services/PersistantData";
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { useCommonStyles } from "../../Styles";
import _ from "lodash";
import { ck_u_password, ck_u_username, defDateFormat, empty, req_cust, req_cust_area, req_mobile_module_setting, req_module_setting, req_report_competitorpricecheck, req_salesman, req_report_competitorpricecheckreport, req_delete_file, req_cust_category, req_compt, req_compt_categorydetails, req_compt_category, req_compt_product, req_report_competitorpricecheckanalysis, req_report_competitorpricecheckanalysisreport } from "../../Helper/Constants";
import moment from "moment";
import VirtualListCB from "../../Components/VirtualListCB";
import API_Service, { axiosDefaultConfig, axiosURIencode } from "../../Services/API_Service";
import DateRangePickerModal from "../../Components/DateRangePickerModal";
import { format } from "date-fns";
import useCookieHook from "../../Services/useCookieHook";
import {Assessment as AssessmentIcon} from '@material-ui/icons';
import { useSnackbar } from "notistack";
import { de, getSetting } from "../../Helper/Helper";

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	},
    container: {
        maxHeight: '78vh',
        maxWidth: '92vw',
    },
    table : {
		fontSize: 12,
		borderCollapse: 'separate',
    	borderSpacing: '4px 0px'
	},
    tableHeadRow: {
        backgroundColor: '#424242',
		fontSize: 10,
		padding: 0,
		margin: 0
    },
    boldText: {
        fontWeight: 'bold',
    },
    tableCellAutoWidth: {
		whiteSpace: "nowrap", 
		overflow: "hidden",
		fontSize: 12,
		padding: 0,
		margin: 0
	},
}));

export default function RCompetitorPriceCheckAnalysis() {
    const commonStyle = useCommonStyles();
    const classes = useStyles();
    const [gState, setGState] = useContext(AppContext);
    const singleton = PersistantData.getInstance();
    var {translation: language, client} = gState;
    if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

    const [loading, setLoading] = useState(false);
    const [data, setDataList] = useState([]);
    const [totalList, setTotalList] = useState([]);
    const [total, setTotal] = useState(0);

    const [reportDateRange, setReportDateRange] = useState({});
    const [openReportDateDoc, setOpenReportDateDoc] = useState(false);
    const getOutletVisitDate = () => {
		return !_.isEmpty(reportDateRange) ? moment(reportDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(reportDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

    const [splist, setSPlist] = useState([]);
    const [spView, setSpView] = useState(false);
	const [spSelectedName, setSpSelectedName] = useState(empty);
    const [spSelectedValue, setSpSelectedValue] = useState(empty);

    const [outletList, setOutletList] = useState([]);
    const [outletView, setOutletView] = useState(false);
	const [outletSelectedName, setOutletSelectedName] = useState(empty);
	const [outletSelectedValue, setOutletSelectedValue] = useState(empty);

    const [outletCategoryList, setOutletCategoryList] = useState([]);
    const [categoryListView, setCategoryListView] = useState(false);
    const [categorySelectedName, setCategorySelectedName] = useState(empty);
    const [categorySelectedValue, setCategorySelectedValue] = useState(empty);

    const [searchByArea, setSearchByArea] = useState(false);
    const [areaList, setAreaList] = useState([]);
	const [areaView, setAreaView] = useState(false);
	const [selectedArea, setSelectedArea] = useState(empty);

    const [competitorList, setCompetitorList] = useState([]);
    const [competitorListView, setCompetitorListView] = useState(false);
    const [competitorSelectedName, setCompetitorSelectedName] = useState(empty);
    const [competitorSelectedValue, setCompetitorSelectedValue] = useState(empty);

    const [cmptCategoryList, setCmptCategoryList] = useState([]);
    const [cmptCategoryListView, setCmptCategoryListView] = useState(false);
    const [cmptCategorySelectedName, setCmptCategorySelectedName] = useState(empty);
    const [cmptCategorySelectedValue, setCmptCategorySelectedValue] = useState(empty);

    const [cmptProductList, setCmptProductList] = useState([]);
    const [cmptProductListView, setCmptProductListView] = useState(false);
    const [cmptProductSelectedName, setCmptProductSelectedName] = useState(empty);
    const [cmptProductSelectedValue, setCmptProductSelectedValue] = useState(empty);

    useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
            getOutletList(gState.client);
            getOutletCategory(gState.client);
            getSettings(gState.client);
            loadCompetitorList(gState.client);
            loadCompetitorCategoryList(gState.client);
            loadCompetitorProducts(gState.client);
		}
	}, [gState.client, gState.translation]);

    const getSalesmanList = (_client = client) => {
		API_Service.get(req_salesman, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id});
				}
				setSPlist(splist => (_data));
			} else {
				snack(gState.translation.not_found_salesperson, 'warning');
			}
		});
	}

    const getOutletList = (_client = client) => {
		API_Service.get(req_cust, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_id, cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_id,
                        status: 1
					});
				}
                for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_id, cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_id,
						status: 0
                    });
                }
				setOutletList(arr);
			}
		});
	}

    const getOutletCategory = (_client = client) => {
        API_Service.get(req_cust_category, axiosDefaultConfig(_client)).then(({data}) => {
			if (data) {
				var {result, extraData } = data;

				result = JString(result).toNumber();
				if (result === 1) {
					var _data = [];
					for (let i = 0; i < extraData.length; i++) {
						const {cust_category_name, cust_category_code, cust_category_id, cust_category_status} = extraData[i];
						if (parseInt(cust_category_status) === 1) {
							_data.push({
                                name: cust_category_name,
                                secondary: cust_category_code,
                                value: cust_category_id,
                                status: 1
                            });
						} else {
							_data.push({
                                name: cust_category_name,
                                secondary: cust_category_code,
                                value: cust_category_id,
                                status: 0
                            });
						}
					}
					setOutletCategoryList(()=>(_data));
				}
			}
		}).catch(err => de(err.message, 'customer category list'));
    }

    const getSettings = (_client = client) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const _config = getSetting(extraData, 'customization', {});
				if(_config.searchbyarea){
					setSearchByArea(_config.searchbyarea);
					getAreaList(_client);
				}
			} else {
				snack(language.not_found_salesperson, 'warning');
			}
		});
	}

    const getAreaList = (_client = client) => {
		API_Service.get(req_cust_area, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, value} = extraData[idx];
					_data.push({name: name, secondary: value, value: value});
				}
				setAreaList(_data);
			}
		});
	}

    const loadCompetitorList = (_client = client) => {
		API_Service.get(req_compt, axiosDefaultConfig(_client)).then(({data}) => {
            if (data) {
                const {result, extraData} = data;
                if (parseInt(result) === 1) {
                    const {active_competitor, inactive_competitor} = extraData;
                    var arr = [];
                    for (let i = 0; i < active_competitor.length; i++) {
                        const {id, cmpt_code, cmpt_name} = active_competitor[i];
                        arr.push({
                            name: cmpt_name,
                            secondary: cmpt_code,
                            value: cmpt_code,
                            status: 1
                        });
                    }
                    for (let i = 0; i < inactive_competitor.length; i++) {
                        const {id, cmpt_code, cmpt_name} = inactive_competitor[i];
                        arr.push({
                            name: cmpt_name,
                            secondary: cmpt_code,
                            value: id,
                            status: 0
                        });
                    }
                    setCompetitorList(() => arr);
                }
            }
        }).catch(err => de(err.message, 'customer list'));
	}

    const loadCompetitorCategoryList = (_client = client) => {
		API_Service.get(req_compt_category, axiosDefaultConfig(_client)).then(({data}) => {
            const {result, extraData} = data;
            if (parseInt(result) === 1) {
                var arr = [];
                for (let i = 0; i < extraData.length; i++) {
                    const {id, categoryIdenitifierId, category_name, category_status} = extraData[i];
                    arr.push({
                        name: category_name,
                        secondary: categoryIdenitifierId,
                        value: categoryIdenitifierId,
                        status: parseInt(category_status)
                    });
                }
                setCmptCategoryList(() => arr);
            }
        }).catch(err => de(err.message, 'customer list'));
	}

    const loadCompetitorProducts = (_client = client) => {
        API_Service.get(req_compt_product, axiosDefaultConfig(_client)).then(({data}) => {
            const {result, extraData} = data;
            if(result === 1){
                var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {product_name, product_code, status} = extraData[idx];
                    _data.push({name: product_name, secondary: product_code, value: product_code, status: parseInt(status)});
				}
                setCmptProductList(_data);
            }
        }).catch(de);
    }

    const SPViewClose = (newValue) => {
		setSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setSpSelectedValue(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setSpSelectedName(spname);
		}
	}

    const OutletViewClose = (newValue) => {
		setOutletView(false);
		if (newValue) {
			var custvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custvalue += newValue[i].value;
				} else {
					custvalue += newValue[i].value + ",";
				}
			}
			setOutletSelectedValue(custvalue);

			var custname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custname += newValue[i].name;
				} else {
					custname += newValue[i].name + ",";
				}
			}
			setOutletSelectedName(custname);
		}
	}

    const OutletCategoryViewClose = (newValue) => {
        setCategoryListView(false);
        if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setCategorySelectedValue(value);

			var name = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					name += newValue[i].name;
				} else {
					name += newValue[i].name + ",";
				}
			}
			setCategorySelectedName(name);
		}
    }

    const AreaViewClose = (newValue) => {
		setAreaView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setSelectedArea(value);
		}
	}

    const CompetitorViewClose = (newValue) => {
        setCompetitorListView(false);
        if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setCompetitorSelectedValue(value);

			var name = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					name += newValue[i].name;
				} else {
					name += newValue[i].name + ",";
				}
			}
			setCompetitorSelectedName(name);
		}
    }

    const CmptCategoryViewClose = (newValue) => {
        setCmptCategoryListView(false);
        if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setCmptCategorySelectedValue(value);

			var name = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					name += newValue[i].name;
				} else {
					name += newValue[i].name + ",";
				}
			}
			setCmptCategorySelectedName(name);
		}
    }

    const CmptProductViewClose = (newValue) => {
        setCmptProductListView(false);
        if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setCmptProductSelectedValue(value);

			var name = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					name += newValue[i].name;
				} else {
					name += newValue[i].name + ",";
				}
			}
			setCmptProductSelectedName(name);
		}
    }

    const getReportData = (_client = client) => {
        setLoading(true);
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(reportDateRange)) {
			st_date = format(reportDateRange.startDate, defDateFormat);
			st_date_to = format(reportDateRange.endDate, defDateFormat);
		}
        const params = axiosURIencode({
			date_from: st_date,
			date_to: st_date_to,
            salespersonId: spSelectedValue,
            outlet: outletSelectedValue,
            outlet_category: categorySelectedValue,
            area: selectedArea,
            competitor: competitorSelectedValue,
            competitor_category: cmptCategorySelectedValue,
            competitor_product: cmptProductSelectedValue
		});

		API_Service.get(`${req_report_competitorpricecheckanalysis}?${params}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
                const {data, per_day, total} = extraData;
				setDataList(data);
                setTotalList(totalList => (per_day));
                setTotal(total);
			} else {
				setDataList([]);
				snack(language.no_data, 'warning');
			}
			setLoading(false);
		});
    }

    const onGenerateReport = (_client = client) => {
        setLoading(true);
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(reportDateRange)) {
			st_date = format(reportDateRange.startDate, defDateFormat);
			st_date_to = format(reportDateRange.endDate, defDateFormat);
		}
        const params = axiosURIencode({
			date_from: st_date,
			date_to: st_date_to,
            salespersonId: spSelectedValue,
            outlet: outletSelectedValue,
            outlet_category: categorySelectedValue,
            area: selectedArea,
            competitor: competitorSelectedValue,
            competitor_category: cmptCategorySelectedValue,
            competitor_product: cmptProductSelectedValue
		});

        API_Service.get(`${req_report_competitorpricecheckanalysisreport}?${params}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
                window.open(extraData.url, '_blank');
                const delay = setTimeout(() => {
                    API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosDefaultConfig(_client));
                    clearTimeout(delay);
                }, 5000);
            } else {
                snack(language.no_data, 'warning');
            }
			setLoading(false);
        });
    }

	return (
		<div>
            <div className={classes.viewInRow}>
                <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setSpView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{spSelectedName ? _.truncate(spSelectedName, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
                <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setOutletView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{outletSelectedName ? _.truncate(outletSelectedName, {length: 20}) : language.customer}
					</Typography>
				</Button>
                <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setCategoryListView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{categorySelectedName ? _.truncate(categorySelectedName, {length: 20}) : language.category}
					</Typography>
				</Button>
                {
					searchByArea && (
						<Button
							style={{width: 300, marginRight: 10}}
							variant="outlined"
							onClick={() => setAreaView(true)}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}
							</Typography>
						</Button>
					)
				}
                <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setCompetitorListView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{competitorSelectedName ? _.truncate(competitorSelectedName, {length: 20}) : language.competitor}
					</Typography>
				</Button>
                <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setCmptCategoryListView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{cmptCategorySelectedName ? _.truncate(cmptCategorySelectedName, {length: 20}) : language.competitor + " " + language.category}
					</Typography>
				</Button>
                <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setCmptProductListView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{cmptProductSelectedName ? _.truncate(cmptProductSelectedName, {length: 20}) : language.competitor + " " + language.product}
					</Typography>
				</Button>
                <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setOpenReportDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getOutletVisitDate()}
					</Typography>
				</Button>
                <Button
					style={{marginRight: 10}}
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getReportData()}
					disabled={loading ? true : false}>
					<Typography className={commonStyle.searchBtnFont}>{language.view}</Typography>
					{
						loading ? <CircularProgress size={20}/> : ''
					}
				</Button>
                <Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => onGenerateReport()}
					disabled={loading ? true : false}>
					<Typography className={commonStyle.searchBtnFont}>Generate Report</Typography>
					{
						loading ? <CircularProgress size={20}/> : ''
					}
				</Button>
            </div>
            <div style={{height: 20}}/>
            {
                data.length > 0 && (
                    <TableContainer component={Paper} className={classes.container}>
                        <Table stickyHeader cellSpacing="0" cellPadding="0" sx={{ minWidth: 650 }} size="small" className={classes.table} aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeadRow} align="left" colSpan={Object.keys(totalList).length + 10}><Typography component="h6" className={classes.boldText}>Competitor Price Check Analysis</Typography></TableCell>
                                    <TableCell className={classes.tableHeadRow} align="left" colSpan={2}><React.Fragment>
                                    </React.Fragment></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableHeadRow} align="left">No</TableCell>
                                    <TableCell className={classes.tableHeadRow} colSpan={4} align="left" style={{width:100}}>Outlet</TableCell>
                                    <TableCell className={classes.tableHeadRow} align="left">Category</TableCell>
                                    <TableCell className={classes.tableHeadRow} align="left">Chain</TableCell>
                                    { Object.keys(totalList).map((drow, dindex) => ( <TableCell key={`title${dindex}`} className={classes.tableHeadRow} align="left">{drow}</TableCell> )) }
                                    <TableCell className={classes.tableHeadRow} align="left">Total</TableCell>
									<TableCell className={classes.tableHeadRow} align="left"> &nbsp; Last Check In By</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell className={classes.tableCellAutoWidth} style={{backgroundColor: row.total > 0 ? '' : red[400]}} align="left">{index + 1}</TableCell>
                                            <TableCell className={classes.tableCellAutoWidth} style={{backgroundColor: row.total > 0 ? '' : red[400]}} colSpan={4} align="left">{row.outlet}</TableCell>
                                            <TableCell className={classes.tableCellAutoWidth} align="left">{row.category}</TableCell>
                                            <TableCell className={classes.tableCellAutoWidth} align="left">{row.chain}</TableCell>
                                            {Object.keys(row.data).map((drow, dindex) => {
												if (row.data[drow] === 0) {
													return (<TableCell className={classes.tableCellAutoWidth} key={`data${index}${dindex}`} align="left"></TableCell>);
												} else {
													return (<TableCell className={classes.tableCellAutoWidth} key={`data${index}${dindex}`} align="left">{row.data[drow]}</TableCell>);
												}
											})}
                                            <TableCell className={classes.tableCellAutoWidth} style={{backgroundColor: row.total > 0 ? '' : red[400]}} align="left">{row.total > 0 ? row.total : ''}</TableCell>
											<TableCell className={classes.tableCellAutoWidth} align="left"> &nbsp; {row.last_checkin_by}</TableCell>
                                        </TableRow>
                                    ))
                                }
                                <TableRow style={{ flexShrink: 0, background: '#424242', padding: '10px', position: 'sticky', bottom: 0, zIndex: 1000 }}>
                                    <TableCell rowSpan={1} />
                                    <TableCell className={classes.tableCellAutoWidth} colSpan={6}>Total</TableCell>
                                    {Object.keys(totalList).map((drow, dindex) => (
                                        <TableCell className={classes.tableCellAutoWidth} key={`footer${dindex}`} align="left">{totalList[drow]}</TableCell>
                                    ))}
                                    <TableCell className={classes.tableCellAutoWidth} align="left">{total}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }

            <VirtualListCB
				open={spView}
				setClose={data => SPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
			/>

            <VirtualListCB
				open={outletView}
				setClose={data => OutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
                filteration={1}
			/>

            <VirtualListCB
				open={categoryListView}
				setClose={data => OutletCategoryViewClose(data)}
				title={"Outlet Category"}
				data={outletCategoryList}
				language={language}
                filteration={1}
			/>

            <VirtualListCB
				open={areaView}
				setClose={data => AreaViewClose(data)}
				title={"Area"}
				data={areaList}
				language={language}
			/>

            <VirtualListCB
				open={competitorListView}
				setClose={data => CompetitorViewClose(data)}
				title={"Competitor"}
				data={competitorList}
				language={language}
                filteration={1}
			/>

            <VirtualListCB
				open={cmptCategoryListView}
				setClose={data => CmptCategoryViewClose(data)}
				title={"Competitor Category"}
				data={cmptCategoryList}
				language={language}
                filteration={1}
			/>

            <VirtualListCB
				open={cmptProductListView}
				setClose={data => CmptProductViewClose(data)}
				title={"Competitor Product"}
				data={cmptProductList}
				language={language}
                filteration={1}
			/>

            <DateRangePickerModal
				setPositive={range => {
					setReportDateRange(range);
					setOpenReportDateDoc(false)
				}}
				setNegative={() => {
					setOpenReportDateDoc(false)
				}}
				setClose={() => {
					setOpenReportDateDoc(false)
				}}
                limit={12}
				open={openReportDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>
        </div>
	)
}