import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import {grey, red} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import {useCommonStyles} from '../../Styles';
import {
    ck_u_password,
    ck_u_username,
    defDateFormat,
    empty,
    req_cust,
    req_cust_area,
    req_module_setting,
    req_report_stocknearexpiry,
    req_salesman,
    req_stock,
    stock_near_expiry_report_url
} from '../../Helper/Constants';
import _ from 'lodash';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import {useSnackbar} from 'notistack';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import moment from 'moment';
import VirtualListCB from '../../Components/VirtualListCB';
import format from 'date-fns/format';
import {de, getSetting} from '../../Helper/Helper';
import useCookieHook from "../../Services/useCookieHook";
import MUIDataTable from 'mui-datatables';
import {Checkbox, IconButton, Tooltip} from '@material-ui/core';
import {Assessment as AssessmentIcon} from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	}, card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	textleft: {
		textAlign: "left"
	},
	dropzone: {
		width: '50%',
		borderRadius: 5
	},
	dropzoneText: {
		color: grey[600]
	},
	adverPaper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: theme.spacing(1.5)
	},
	adverView: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1)
	},
	textarea: {
		width: '100%'
	},
	dateBtn: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	statusBtn: {
		marginLeft: 30,
		width: 150
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	},
	alignRight: {
		display: 'flex',
		marginBottom: 10,
		flex: 1,
		flexDirection: 'row'
	}
}));

const tableHead = [
	{
		name: "st_id",
		label: "ID",
		options: {
			display: true
		}
	},
	// {
	//     name:"created_date",
	//     label:"Date",
	//     options:{
	//         display: true
	//     }
	// },
	{
		name: "cust_code",
		label: "Outlet",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Outlet Name",
		options: {
			display: true
		}
	},
	{
		name: "name",
		label: "Agent Name",
		options: {
			display: true
		}
	},
	{
		name: "cust_area",
		label: "Outlet Area",
		options: {
			display: true
		}
	},
	// {
	// 	name: "company",
	// 	label: "Branch",
	// 	options: {
	// 		display: true,
	// 		customBodyRender: (value) => value ? value.toUpperCase() : ''
	// 	}
	// },
	{
		name: "product_code",
		label: "Product Code",
		options: {
			display: true
		}
	},
	{
		name: "product_name",
		label: "Product Name",
		options: {
			display: true
		}
	},
	{
		name: "current_quantity_uom",
		label: "Quantity",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch(value) {
					case '1888888':
						return 'High';
					case '1555555':
						return 'Medium';
					case '1111111':
						return 'Low';
					case '-111111':
						return 'Delist';
					default:
						return value;
				}
			}
		}
	},
	// {
	//     name:"unit_uom",
	//     label:"UOM",
	//     options:{
	//         display: true
	//     }
	// },
	{
		name: "checkin_date",
		label: "Check-In",
		options: {
			display: true
		}
	},
	{
		name: "expiry_date",
		label: "Expiry Date",
		options: {
			display: true
		}
	},
	{
		name: "expiry_days_count",
		label: "Days To Expiry",
		options: {
			display: true
		}
	}
]

export default function ROOS() {
	const commonStyle = useCommonStyles();
	const classes = useStyles();

	const singleton = PersistantData.getInstance();
	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}

	const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const [nClient, setClient] = useState(gState.client);

	const expiry_date = [
		{name: 'LAST TWO MONTHS', value: '-2'},
		{name: 'LAST MONTH', value: '-1'},
		{name: 'CURRENT MONTH', value: '+0'},
		{name: 'NEXT MONTH', value: '+1'},
		{name: 'NEXT TWO MONTHS', value: '+2'},
		{name: 'NEXT THREE MONTHS', value: '+3'},
		{name: 'NEXT THREE TO SIX MONTHS', value: '4'},
		{name: 'NEXT SIX TO TWELVE MONTHS', value: '5'},
		{name: 'RESET', value: 'reset'}
	]

	const [splist, setSPlist] = useState([]);
	const [outletList, setOutletList] = useState([]);
	const [itemList, setItemList] = useState([]);
	const [stockNearExpiryList, setStockNearExpiryList] = useState([]);

	const [stockNearDateRange, setStockNearDateRange] = useState({});
	const [openStockNearDateDoc, setOpenStockNearDateDoc] = useState(false);
	const [stockNearSpView, setStockNearSpView] = useState(false);
	const [stockNearOutletView, setStockNearOutletView] = useState(false);
	const [stockNearOutlet, setStockNearOutlet] = useState(empty);
	const [stockNearOutletSelect, setStockNearOutletSelected] = useState(empty);
	const [stockNearSpSelect, setStockNearSpSelected] = useState(empty);
	const [stockNearSalesperson, setStockNearSalesperson] = useState(empty);
	const [stockNearProductView, setStockNearProductView] = useState(false);
	const [stockNearProduct, setStockNearProduct] = useState(empty);
	const [stockNearProductSelect, setStockNearProductSelected] = useState(empty);
	const [stockNearExpiryView, setStockNearExpiryView] = useState(null);
	const [stockNearExpiry, setStockNearExpiry] = useState(empty);
	const [stockNearExpiryValue, setStockNearExpiryValue] = useState(0);
	const [tableHeader, setTableHeader] = useState([]);

	const [areaList, setAreaList] = useState([]);
	const [areaView, setAreaView] = useState(false);
	const [selectedArea, setSelectedArea] = useState(empty);

	const [disableStatus, setDisableStatus] = useState(true);
	const [limit, setLimit] = useState(5);
	const [checking, doChecking] = useState(false);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
			getOutletList(gState.client);
			loadStocksList(gState.client);
			loadConfig(gState.client);
			getAreaList(gState.client);
		}
		setTableHeader(tableHead);
	}, [gState.client, gState.translation]);

	useEffect(() => {
		if(stockNearProductSelect === empty && stockNearExpiry === empty && stockNearSpSelect === empty && stockNearOutletSelect === empty){
			setDisableStatus(true);
		}else{
			setDisableStatus(false);
		}
	}, [checking]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const loadConfig = (_client = client) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				// const _config = getSetting(extraData, 'customization', {});
				const _config = getSetting(extraData, 'modules', {});
				if (_config['branch'] == true) {
					tableHead.splice(5, 0, 	{
						name: "company",
						label: "Branch",
						options: {
							display: true,
							customBodyRender: (value) => value ? value.toUpperCase() : ''
						}
					});
				}


			}
		});
	}

	const getAreaList = (_client = client) => {
		API_Service.get(req_cust_area, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {area_name, area_code, area_status} = extraData[idx];
					_data.push({name: area_name, secondary: area_code, value: area_code, status: parseInt(area_status)});
				}
				setAreaList(_data);
			}
		});
	}

	const AreaViewClick = () => {
		setAreaView(true);
	}

	const AreaViewClose = (newValue) => {
		setAreaView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setSelectedArea(value);
		}
	}

	const getStockNearExpiry = (_client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(stockNearDateRange)) {
			st_date = format(stockNearDateRange.startDate, defDateFormat);
			st_date_to = format(stockNearDateRange.endDate, defDateFormat);
		}
		var parameters = {
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: stockNearSpSelect,
			cust_code: stockNearOutletSelect,
			product_code: stockNearProductSelect,
			expiry_date: stockNearExpiry.value,
			area: selectedArea,
			username: cookies(ck_u_username)
		};
		if (gState.company) {
			parameters.company = gState.company;
		}
		const param = axiosURIencode(parameters);
		API_Service.get(`${req_report_stocknearexpiry}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					var _arr = extraData[idx];
					_arr['created_date'] = moment(_arr['created_date']).format('DD/MM/YYYY');
					_arr['checkin_date'] = moment(_arr['checkin_time']).format('DD/MM/YYYY');
					_arr['expiry_date'] = moment(_arr['expiry_date']).format('DD/MM/YYYY');
					_arr['current_quantity_uom'] = _arr['current_quantity'] + ' ' + _arr['unit_uom'];
					_data.push(_arr);
				}
				setStockNearExpiryList(stockNearExpiryList => (_data));
			} else {
				setStockNearExpiryList(stockNearExpiryList => ([]));
				snack(language.no_data, 'warning');
			}
		});
	}

	const getSalesmanList = (_client = nClient) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_salesman}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login, login_status} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
				}
				setSPlist(splist => (_data));
			} else {
				snack(gState.translation.not_found_salesperson, 'warning');
			}
		});
	}

	const getOutletList = (_client = nClient) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_cust}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 1
					});
				}
				for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 0
					});
				}
				setOutletList(arr);
			}
		});
	}

	const loadStocksList = (_client = nClient) => {
		var req = `${req_stock}-1`;
		API_Service.get(req, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			const {stock: stockApi} = extraData;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < stockApi.length; idx++) {
					const {product_name, product_code, product_status} = stockApi[idx];
					_data.push({name: product_name, secondary: product_code, value: product_code, status: parseInt(product_status)});
				}
				setItemList(itemList => (_data));
			}
			de(data);
		}).catch(de);
	}

	const StockNearSPViewClick = () => {
		setStockNearSpView(true);
	}

	const StockNearSPViewClose = (newValue) => {
		setStockNearSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setStockNearSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setStockNearSalesperson(spname);
		}
		doChecking(!checking);
	}

	const StockNearOutletViewClick = () => {
		setStockNearOutletView(true);
	}

	const StockNearOutletViewClose = (newValue) => {
		setStockNearOutletView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setStockNearOutletSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setStockNearOutlet(spname);
		}
		doChecking(!checking);
	}

	const StockNearProductViewClick = () => {
		setStockNearProductView(true);
	}

	const StockNearProductViewClose = (newValue) => {
		setStockNearProductView(false);
		if (newValue) {
			var itemsvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					itemsvalue += newValue[i].value;
				} else {
					itemsvalue += newValue[i].value + ",";
				}
			}
			setStockNearProductSelected(itemsvalue);

			var itemsname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					itemsname += newValue[i].name;
				} else {
					itemsname += newValue[i].name + ",";
				}
			}
			setStockNearProduct(itemsname);
		}
		doChecking(!checking);
	}


	const StockNearExpiryOpen = (event) => {
		setStockNearExpiryView(event.currentTarget);
	};

	const StockNearExpiryClick = (each) => {
		if(each.value === 'reset'){
			setStockNearExpiry(empty);
			setLimit(5);
		}else{
			setStockNearExpiry(each);
			setLimit(0);
		}
		setStockNearExpiryView(null);
		doChecking(!checking);
	};

	const StockNearExpiryClose = (data) => {
		setStockNearExpiryView(null);
	};

	const getStockNearDate = () => {
		return !_.isEmpty(stockNearDateRange) ? moment(stockNearDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(stockNearDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

	const generateStockNearReport = () => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(stockNearDateRange)) {
			st_date = format(stockNearDateRange.startDate, defDateFormat);
			st_date_to = format(stockNearDateRange.endDate, defDateFormat);
		}
		const date_range = `${st_date} to ${st_date_to}`;
		const branch = gState.company ? gState.company : '';
		var link = JString(stock_near_expiry_report_url)
			.replaceInfinity('@client', client)
			.replaceInfinity('@reportDate', encodeURIComponent(date_range))
			.replaceInfinity('@salespersonId', stockNearSpSelect)
			.replaceInfinity('@cust_code', stockNearOutletSelect)
			.replaceInfinity('@expiry_date', stockNearExpiry.value)
			.replaceInfinity('@product_code', stockNearProductSelect)
			.replaceInfinity('@branch', branch)
			.replaceInfinity('@username', cookies(ck_u_username))
			.str;
		window.open(link, "_blank");
	}


	return (
		<div>
			{/* <Typography className={classes.heading}>{ language.generate_stock_near_expiry_date_report }</Typography> */}
			<div className={classes.viewInRow}>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => StockNearSPViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{stockNearSalesperson ? _.truncate(stockNearSalesperson, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => StockNearOutletViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{stockNearOutlet ? _.truncate(stockNearOutlet, {length: 20}) : language.customer}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => AreaViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => StockNearProductViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{stockNearProduct ? _.truncate(stockNearProduct, {length: 20}) : language.items}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={StockNearExpiryOpen}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{stockNearExpiry ? _.truncate(stockNearExpiry.name, {length: 20}) : language.expiry_date}
					</Typography>
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={stockNearExpiryView}
					keepMounted
					open={Boolean(stockNearExpiryView)}
					onClose={StockNearExpiryClose}
				>
					{
						expiry_date.map((each) => {
							if(each.value === 'reset'){
								return (<MenuItem style={{ color: red[400] }} key={each.value} onClick={() => StockNearExpiryClick(each)}>{each.name}</MenuItem>);
							}else{
								return (<MenuItem key={each.value} onClick={() => StockNearExpiryClick(each)}>{each.name}</MenuItem>);
							}
						})
					}
				</Menu>
				{/* <div style={{width:10}}/>
                <Button
                    style={{width:300}}
                    variant="outlined"
                    onClick={()=>setOpenStockNearDateDoc(true)}>
                    <Typography className={commonStyle.searchBtnFont}>
                        { getStockNearDate() }
                    </Typography>
                </Button> */}
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					disabled={disableStatus}
					onClick={() => getStockNearExpiry()}>
					<Typography className={commonStyle.searchBtnFont}>
						{language.view}
					</Typography>
				</Button>
			</div>
			<div style={{height: 20}}/>
			{
				stockNearExpiryList.length > 0 && (
					<MUIDataTable
						title={language.stock_near_expiry}
						data={stockNearExpiryList}
						columns={tableHeader}
						options={{
							search: false,
							print: false,
							filter: false,
							download: false,
							selectableRows: 'none',
							rowsPerPage: 100,
							rowsPerPageOptions: [100, 200, 300, 400],
							responsive: 'simple',
							onColumnViewChange: (changedColumn, action) => {
								if(tableHeader.some(item => changedColumn === item.name)){
									var index = tableHeader.findIndex(item => changedColumn === item.name);
								}
								switch(action){
									case 'add':
										tableHeader[index].options.display = true;
									case 'remove':
										tableHeader[index].options.display = false;
								}
								setTableHeader(tableHeader);
							},
							setTableProps: () => ({size: 'small'}),
							customToolbar: () =>
								<>
									<CheckinTableReportButton onGenerateReport={generateStockNearReport}/>
								</>
						}}
					/>
				)
			}

			<VirtualListCB
				open={stockNearSpView}
				setClose={data => StockNearSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={stockNearProductView}
				setClose={data => StockNearProductViewClose(data)}
				title={"Product"}
				data={itemList}
				language={language}
				limit={limit}
				filteration={1}
			/>

			<VirtualListCB
				open={stockNearOutletView}
				setClose={data => StockNearOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={areaView}
				setClose={data => AreaViewClose(data)}
				title={"Area"}
				data={areaList}
				language={language}
				filteration={1}
			/>

			{/* <VirtualList
                open={stockNearExpiryView}
                setClose={data=>StockNearExpiryViewClose(data)}
                title={"Expiry Date"}
                data={expiry_date}
                language={language}
            /> */}

			<DateRangePickerModal
				setPositive={range => {
					setStockNearDateRange(range);
					setOpenStockNearDateDoc(false)
				}}
				setNegative={() => {
					setOpenStockNearDateDoc(false)
				}}
				setClose={() => {
					setOpenStockNearDateDoc(false)
				}}
				open={openStockNearDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>
		</div>
	)
}

function CheckinTableReportButton({onGenerateReport}) {
	return (
		<React.Fragment>
			<Tooltip title={'Generate Report'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => onGenerateReport()} aria-label="addnew">
					<AssessmentIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}
