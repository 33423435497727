import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import {grey, yellow} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import {useCommonStyles} from '../../Styles';
import {
	checkin_report_url,
	ck_u_password,
	ck_u_username,
	defDateFormat,
	empty,
	req_cust,
	req_cust_area,
	req_module_setting,
    req_gallery_type,
    req_report_agentattend,
    req_salesman,
	req_mobile_module_setting,
	req_settings_branches,
	req_cust_category,
} from '../../Helper/Constants';
import _ from 'lodash';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import {useSnackbar} from 'notistack';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import moment from 'moment';
import VirtualListCB from '../../Components/VirtualListCB';
import format from 'date-fns/format';
import useCookieHook from "../../Services/useCookieHook";
import GenerateView from "../../Components/GenerateView";
import MUIDataTable from 'mui-datatables';
import {Chip, IconButton, Tooltip} from '@material-ui/core';
import {Assessment as AssessmentIcon} from '@material-ui/icons';
import { de, getSetting } from '../../Helper/Helper';

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	}, card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	textleft: {
		textAlign: "left"
	},
	dropzone: {
		width: '50%',
		borderRadius: 5
	},
	dropzoneText: {
		color: grey[600]
	},
	adverPaper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: theme.spacing(1.5)
	},
	adverView: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1)
	},
	textarea: {
		width: '100%'
	},
	dateBtn: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	statusBtn: {
		marginLeft: 30,
		width: 150
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	},
	alignRight: {
		display: 'flex',
		marginBottom: 10,
		flex: 1,
		flexDirection: 'row'
	}
}));

const tableHead = [
	{
		name: "cust_code",
		label: "Outlet Code",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Outlet Name",
		options: {
			display: true
		}
	},
	{
		name: "type_of_visit",
		label: "Type Of Visit",
		options: {
			display: true
		}
	},
	{
		name: "person_met",
		label: "Person Met",
		options: {
			display: true
		}
	},
	{
		name: "remark2",
		label: "Phone No",
		options: {
			display: true
		}
	},
	// {
	// 	name: "company",
	// 	label: "Branch",
	// 	options: {
	// 		display: true,
	// 		customBodyRender: (value) => value ? value.toUpperCase() : ''
	// 	}
	// },
	{
		name: "cust_category_name",
		label: "Outlet Category",
		options: {
			display: true
		}
	},
	{
		name: "name",
		label: "Agent Name",
		options: {
			display: true
		}
	},
	{
		name: "checkin_date",
		label: "Check-In Date",
		options: {
			display: true
		}
	},
	{
		name: "checkin_time",
		label: "Check-In Time",
		options: {
			display: true
		}
	},
	{
		name: "checkin_coordinates",
		label: "Checkin Coordinates",
		options: {
			display: false
		}
	},
	{
		name: "checkout_date",
		label: "Check-Out Date",
		options: {
			display: true
		}
	},
	{
		name: "checkout_time",
		label: "Check-Out Time",
		options: {
			display: true
		}
	},
	{
		name: "time_spent_hour",
		label: "Time Spent (Hour)",
		options: {
			display: true
		}
	},
	{
		name: "time_spent_minutes",
		label: "Time Spent (Minute)",
		options: {
			display: true
		}
	},
	{
		name: "checkout_coordinates",
		label: "Checkout Coordinates",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<a href={'https://www.google.com/search?q='+value} target='_blank' style={{color:'#fff'}}>{value}</a>
				)
			}
		}
	},
	{
		name: "status",
		label: "Status",
		options: {
			display: true
		}
	}
]

export default function RAgentAttend() {
	const commonStyle = useCommonStyles();
	const classes = useStyles();
	const singleton = PersistantData.getInstance();
	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}
	const typeOptions = [
		{name: 'Basic Version', value: 'simply'},
		{name: 'Detailed Version', value: 'normal'},
		{name: 'Category Version', value: 'category'}
	]

	const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();

	const [splist, setSPlist] = useState([]);
	const [outletList, setOutletList] = useState([]);
	const [categoryList, setCategoryList] = useState([]);
	const [customerCategoryList, setCustomerCategoryList] = useState([]);
	const [printView, setPrintView] = useState();

	const [agentAttendDateRange, setAgentAttendDateRange] = useState({});
	const [openAgentAttendDateDoc, setOpenAgentAttendDateDoc] = useState(false);
	const [agentAttendSpView, setAgentAttendSpView] = useState(false);
	const [agentAttendOutletView, setAgentAttendOutletView] = useState(false);
	const [customerCategoryView, setCustomerCategoryView] = useState(false);
	const [agentAttendOutlet, setAgentAttendOutlet] = useState(empty);
	const [agentAttendOutletSelect, setAgentAttendOutletSelected] = useState(empty);
	const [selectedCustomerCategory, setSelectedCustomerCategory] = useState(empty);
	const [selectedCustomerCategoryValue, setSelectedCustomerCategoryValue] = useState(empty);
	const [agentAttendSpSelect, setAgentAttendSpSelected] = useState(empty);
	const [agentAttendSalesperson, setAgentAttendSalesperson] = useState(empty);
	const [agentAttendList, setAgentAttendList] = useState([]);
	const [tableHeader, setTableHeader] = useState([]);
	const [areaList, setAreaList] = useState([]);
	const [areaView, setAreaView] = useState(false);
	const [selectedArea, setSelectedArea] = useState(empty);
	const [designationModule, setDesignationModule] = useState(false);

	const [branchesList, setBranchesList] = useState([]);
	const [branchesView, setBranchesView] = useState(false);
	const [branchSelectedValue, setBranchSelectedValue] = useState(empty);
	const [branchSelectedName, setBranchSelectedName] = useState(empty);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
			getOutletList(gState.client);
			loadGalleryType(gState.client);
			loadConfig(gState.client);
			getAreaList(gState.client);
			loadCustCategoryList(gState.client);
			getBranches(gState.client);
		}
		// const table = JSON.parse(localStorage.getItem('RAgentAttendTable'));
		// if (!table) {
		// 	localStorage.setItem('RAgentAttendTable', JSON.stringify(tableHead));
		// }
		setTableHeader(tableHead);
	}, [gState.client, gState.translation]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const loadConfig = (_client = client) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				// const _config = getSetting(extraData, 'customization', {});
				const _config = getSetting(extraData, 'modules', {});
				if (_config['branch'] == true) {
					if (!tableHead.some(col => col.name === "company" && col.label === "Branch")) {
						tableHead.splice(5, 0, {
							name: "company",
							label: "Branch",
							options: {
								display: true,
								customBodyRender: (value) => value ? value.toUpperCase() : ''
							}
						});
					}
				}		
			}
		});
		API_Service.get(req_mobile_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const {module, pdf_images} = extraData;
				for (let m = 0; m < module.length; m++) {
					if (module[m]['module'] === 'app_outlet_designation') {
						let setting = parseInt(module[m]['status']);
						if (setting) {
							setDesignationModule(true);
							tableHead.splice(5, 0, {
								name: "designation",
								label: "Designation",
								options: {
									display: true
								}
							});
							tableHead.splice(11, 0, {
								name: "no_of_outlet_sharing",
								label: "No Of Outlet Sharing",
								options: {
									display: true
								}
							});
							tableHead.splice(11, 0, {
								name: "new_customer",
								label: "New Customer",
								options: {
									display: true
								}
							});
							tableHead.splice(11, 0, {
								name: "competitor_update",
								label: "Competitor Update",
								options: {
									display: true
								}
							});
							tableHead.splice(11, 0, {
								name: "next_action",
								label: "Next Action",
								options: {
									display: true
								}
							});
						}
					}
					if (module[m]['module'] === 'app_checkin_type') {
						let setting = parseInt(module[m]['status']);
						if (setting) {

							const exists = tableHead.some(item => item.name === "app_type" );

							if (!exists) {
								tableHead.push({
									name: "app_type",
									label: "App Type",
									options: {
										display: true,
										customBodyRender: (value, tableMeta, updateValue) => {
											let style = {};
	
											switch (value) {
												case "EasyM" :
													style = {backgroundColor: yellow[900]};
													break;
												case "EasyTeam" :
													style = {backgroundColor: "yellowgreen"};
													break;
												default :
													style = {};
													break;
											}
											return (<Chip size="small" style={style} label={value} />);
										}
									}
								});
							}
							
						}
					}
				}
			}
		});
	}

	const getAreaList = (_client = client) => {
		API_Service.get(req_cust_area, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {area_name, area_code, area_status} = extraData[idx];
					_data.push({name: area_name, secondary: area_code, value: area_code, status: parseInt(area_status)});
				}
				setAreaList(_data);
			}
		});
	}

	const AreaViewClick = () => {
		setAreaView(true);
	}

	const AreaViewClose = (newValue) => {
		setAreaView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setSelectedArea(value);
		}
	}

	const getBranches = (_client = client) => {
		API_Service.get(req_settings_branches, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				setBranchesList(branchesList => (extraData));
			}
		}).catch(de);
	}

	const BranchesViewClose = (newValue) => {
		setBranchesView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setBranchSelectedValue(value);

			var name = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					name += newValue[i].name;
				} else {
					name += newValue[i].name + ",";
				}
			}
			setBranchSelectedName(name);
		}
	}

	const getAgentAttend = (_client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(agentAttendDateRange)) {
			st_date = format(agentAttendDateRange.startDate, defDateFormat);
			st_date_to = format(agentAttendDateRange.endDate, defDateFormat);
		}
		var parameters = {
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: agentAttendSpSelect,
			type: 'simple',
			username: cookies(ck_u_username),
			cust_code: agentAttendOutletSelect,
			cust_category_id: selectedCustomerCategoryValue,
			area: selectedArea
		};
		if (gState.company) {
			parameters.company = gState.company;
		}
		if (branchSelectedValue !== empty) {
			parameters.company = JSON.stringify(branchSelectedValue.split(","));
		}
		const param = axiosURIencode(parameters);
		API_Service.get(`${req_report_agentattend}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					var _arr = extraData[idx];
					let remark3 = _arr['remark3'].trim() !== "" ? JSON.parse(_arr['remark3']) : [];
					if (_arr['checkin_time']) {
						_arr['checkin_date'] = moment(_arr['checkin_time']).format('DD/MM/YYYY');
						_arr['checkin_time'] = moment(_arr['checkin_time']).format('HH:mmA');
					}
					if (_arr['checkout_time']) {
						_arr['checkout_date'] = moment(_arr['checkout_time']).format('DD/MM/YYYY');
						_arr['checkout_time'] = moment(_arr['checkout_time']).format('HH:mmA');
					}
					if (_arr['status'] === '1') {
						_arr['status'] = "In Progress";
					} else if (_arr['status'] === '2') {
						_arr['status'] = "Completed";
					}
					_arr['checkin_coordinates'] = _arr['location_lat'] + ',' + _arr['location_lng'];
					_arr['checkout_coordinates'] = _arr['checkout_lat'] + ',' + _arr['checkout_lng'];
					
					var schedule = _arr['schedule'] && JSON.parse(_arr['schedule']);
					if (Object.keys(schedule).length > 0) {
						_arr['type_of_visit'] = "Scheduled";
					} else {
						_arr['type_of_visit'] = "Unscheduled";
					}

					_arr['time_spent_hour'] = _arr['time_spent_hour'] += ' hour';
					_arr['time_spent_minutes'] = _arr['time_spent_minutes'] += ' minutes';

					if (remark3.obj) {
						for (let x = 0; x < remark3.obj.length; x++) {
							var info = remark3.obj[x];
							if (info.type === 'input') {
								// phone & remark
								if (info.code === 'phone') { _arr['remark2'] = info.value; }
								if (info.code === 'remark') { _arr['remark1'] = info.value; }

								if (designationModule) {
									if (info.code === 'designation') { _arr['designation'] = info.value; }
									if (info.code === 'newcustomer') { _arr['new_customer'] = info.value; }
									if (info.code === 'competitorupdate') { _arr['competitor_update'] = info.value; }
									if (info.code === 'nextaction') { _arr['next_action'] = info.value; }
									if (info.code === 'noofoutletsharing') { _arr['no_of_outlet_sharing'] = info.value; }
								}
							}
						}
					}
					_data.push(_arr);
				}
				setAgentAttendList(agentAttendList => (_data));
			} else {
				setAgentAttendList(agentAttendList => ([]));
				snack(language.no_data, 'warning');
			}
		});
	}

	const getSalesmanList = (_client = client) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_salesman}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login, login_status} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
				}
				setSPlist(splist => (_data));
			}
		});
	}

	const getOutletList = (_client = client) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_cust}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 1
					});
				}
				for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 0
					});
				}
				setOutletList(arr);
			}
		});
	}

	const loadGalleryType = (_client = client) =>{
        API_Service.get(req_gallery_type, axiosDefaultConfig(_client)).then(({data}) => {
            const {result, extraData} = data;
            if (result == 1) {
                var arr = [];
                for (let i = 0; i < extraData.length; i++) {
                    const {type_name, type_id, type_status} = extraData[i];
                    arr.push({
                        name: type_name,
						secondary: type_id,
                        value: type_id,
						status: parseInt(type_status)
                    });
                }
                setCategoryList(arr);
            }
        });
    }

	const loadCustCategoryList = (_client = client) => {
		API_Service.get(req_cust_category, axiosDefaultConfig(_client)).then(({data}) => {
			if (data) {
				var {result, extraData } = data;
				if (parseInt(result) === 1) {
					var _arr = [];
					for (let i = 0; i < extraData.length; i++) {
						const {cust_category_id, cust_category_code, cust_category_name, cust_category_status} = extraData[i];
						if (parseInt(cust_category_status) === 1) {
							_arr.push({
								name: cust_category_name,
								secondary: cust_category_code,
								value: cust_category_id,
								status: 1
							});
						} else {
							_arr.push({
								name: cust_category_name,
								secondary: cust_category_code,
								value: cust_category_id,
								status: 0
							});
						}
					}
					setCustomerCategoryList(() => (_arr));
				}
			}
		}).catch(err => de(err.message, 'customer category list'));
	}

	const AgentAttendSPViewClick = () => {
		setAgentAttendSpView(true);
	}

	const AgentAttendSPViewClose = (newValue) => {
		setAgentAttendSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setAgentAttendSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setAgentAttendSalesperson(spname);
		}
	}

	const AgentAttendOutletViewClick = () => {
		setAgentAttendOutletView(true);
	}

	const AgentAttendOutletViewClose = (newValue) => {
		setAgentAttendOutletView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setAgentAttendOutletSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setAgentAttendOutlet(spname);
		}
	}

	const OnCloseCustomerCategoryView = (data) => {
		setCustomerCategoryView(false);
		if (data) {
			var itemsvalue = '';
			for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					itemsvalue += data[i].value;
				} else {
					itemsvalue += data[i].value + ",";
				}
			}
			setSelectedCustomerCategoryValue(itemsvalue);

			var itemsname = '';
			for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					itemsname += data[i].name;
				} else {
					itemsname += data[i].name + ",";
				}
			}
			setSelectedCustomerCategory(itemsname);
		}
	}

	const openPrintSelectView = () => {
		setPrintView(true);
	}

	const printViewClose = (type) => {
		generateAgentAttendReport(type);
		setPrintView(false);
	}

	const getAgentAttendDate = () => {
		return !_.isEmpty(agentAttendDateRange) ? moment(agentAttendDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(agentAttendDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

	const generateAgentAttendReport = (type) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(agentAttendDateRange)) {
			st_date = format(agentAttendDateRange.startDate, defDateFormat);
			st_date_to = format(agentAttendDateRange.endDate, defDateFormat);
		}
		const date_range = `${st_date} to ${st_date_to}`;

		const json = JSON.stringify(tableHeader);

		let branch = gState.company ? gState.company : '';

		if (branchSelectedValue !== empty) {
			branch = JSON.stringify(branchSelectedValue.split(","));
		}

		if(typeof type === 'object' && type !== null){
			var url = checkin_report_url(client, agentAttendSpSelect, date_range, type.type, cookies(ck_u_username), agentAttendOutletSelect, selectedCustomerCategoryValue, type.categories, branch, json);
		}else{
			var url = checkin_report_url(client, agentAttendSpSelect, date_range, type, cookies(ck_u_username), agentAttendOutletSelect, selectedCustomerCategoryValue, null, branch, json);
		}
		window.open(url, '_blank');
	}

	return (
		<div>
			{/* <Typography className={classes.heading}>{ language.generate_agent_attend_report }</Typography> */}
			<div className={classes.viewInRow}>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => AgentAttendSPViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{agentAttendSalesperson ? _.truncate(agentAttendSalesperson, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => AgentAttendOutletViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{agentAttendOutlet ? _.truncate(agentAttendOutlet, {length: 20}) : language.customer}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setCustomerCategoryView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedCustomerCategory ? _.truncate(selectedCustomerCategory, {length: 20}) : "Outlet Category"}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => AreaViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}
					</Typography>
				</Button>
				{
					!gState.company && branchesList.length > 0 && (
						<Button
							style={{width: 300, marginRight: 10}}
							variant="outlined"
							onClick={() => setBranchesView(true)}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{branchSelectedName ? _.truncate(branchSelectedName, {length: 20}) : "Branch"}
							</Typography>
						</Button>
					)
				}
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setOpenAgentAttendDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getAgentAttendDate()}
					</Typography>
				</Button>
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getAgentAttend()}>
					<Typography className={commonStyle.searchBtnFont}>
						{language.view}
					</Typography>
				</Button>
			</div>
			<div style={{height: 20}}/>
			{
				agentAttendList.length > 0 && (
					<MUIDataTable
						title={language.agent_attend}
						data={agentAttendList}
						columns={tableHeader}
						options={{
							search: false,
							print: false,
							filter: false,
							download: false,
							selectableRows: 'none',
							rowsPerPage: 25,
							rowsPerPageOptions: [25, 40, 65, 100],
							responsive: 'simple',
							onColumnViewChange: (changedColumn, action) => {
								if(tableHeader.some(item => changedColumn === item.name)){
									var index = tableHeader.findIndex(item => changedColumn === item.name);
								}
								switch(action){
									case 'add':
										tableHeader[index].options.display = true;
										break;
									case 'remove':
										tableHeader[index].options.display = false;
										break;
								}
								setTableHeader(tableHeader);
							},
							customToolbar: () =>
								<>
									<CheckinTableReportButton onGenerateReport={openPrintSelectView}/>
								</>
						}}
					/>
				)
			}

			<VirtualListCB
				open={agentAttendSpView}
				setClose={data => AgentAttendSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={agentAttendOutletView}
				setClose={data => AgentAttendOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={customerCategoryView}
				setClose={data => OnCloseCustomerCategoryView(data)}
				title={"Outlet Category"}
				data={customerCategoryList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={areaView}
				setClose={data => AreaViewClose(data)}
				title={"Area"}
				data={areaList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={branchesView}
				setClose={data => BranchesViewClose(data)}
				title={"Branch"}
				data={branchesList}
				language={language}
				filteration={0}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setAgentAttendDateRange(range);
					setOpenAgentAttendDateDoc(false)
				}}
				setNegative={() => {
					setOpenAgentAttendDateDoc(false)
				}}
				setClose={() => {
					setOpenAgentAttendDateDoc(false)
				}}
				open={openAgentAttendDateDoc}
				message={language.date_range}
				positive={language.ok}
			/>

			<GenerateView
				classes={{
					paper: commonStyle.listView,
				}}
				id="ringtone-menu"
				keepMounted
				open={printView}
				onClose={() => setPrintView(false)}
				onSelect={printViewClose}
				value={'simply'}
				title={language.generate_type || ''}
				language={language}
				options={typeOptions}
				list={categoryList}
			/>
		</div>
	)
}

function CheckinTableReportButton({onGenerateReport}) {
	return (
		<React.Fragment>
			<Tooltip title={'Generate Report'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => onGenerateReport()} aria-label="addnew">
					<AssessmentIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}
