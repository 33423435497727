import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "auto",
    margin: "auto",
    textAlign: "center",
    padding: theme.spacing(3),
    marginTop: theme.spacing(5),
    borderRadius: 12,
    backgroundColor: "#686868",
    color: "#fff",
    boxShadow: "0 4px 10px rgba(255, 255, 255, 0.1)",
  },
  media: {
    height: 160,
    backgroundSize: "contain",
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    color: "#E5D200",
  },
  description: {
    color: "#ccc",
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: "#E5D200",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#FFC800",
    },
  },
}));

export default function UpgradePrompt() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleUpgrade = () => {
    enqueueSnackbar("Please contact support team for upgrade details !", {
      variant: "info",
      autoHideDuration: 3000,
      style: { backgroundColor: "#444", color: "#fff" },
    });
  };

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image="https://cdn-icons-png.flaticon.com/512/3064/3064197.png"
        title="Upgrade"
      />
      <CardContent>
        <Typography variant="h5" className={classes.title}>
          This feature is only available for Advanced Plan
        </Typography>
        <Typography className={classes.description}>
          Please upgrade to the{" "}
          <strong>Advanced Plan</strong> 
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
          onClick={handleUpgrade}
        >
          Get more details
        </Button>
      </CardContent>
    </Card>
  );
}
