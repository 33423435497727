import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import {grey} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import {useCommonStyles} from '../../Styles';
import {
    ck_u_password,
    ck_u_username,
    defDateFormat,
    empty,
    req_cust,
    req_delete_file,
    req_module_setting,
    req_report_stocktake,
    req_salesman,
    req_stock,
    req_stocktake_report,
    stock_take_report_url,
	req_cust_area,
	req_mobile_module_setting,
	req_cust_category,
	req_cust_chain,
	req_stock_category,
	req_stock_brand
} from '../../Helper/Constants';
import _ from 'lodash';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import {useSnackbar} from 'notistack';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import moment from 'moment';
import VirtualListCB from '../../Components/VirtualListCB';
import format from 'date-fns/format';
import useCookieHook from "../../Services/useCookieHook";
import MUIDataTable from 'mui-datatables';
import {IconButton, Tooltip} from '@material-ui/core';
import {Assessment as AssessmentIcon} from '@material-ui/icons';
import {de, getSetting} from '../../Helper/Helper';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import GenerateView from '../../Components/GenerateView';

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	}, card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	textleft: {
		textAlign: "left"
	},
	dropzone: {
		width: '50%',
		borderRadius: 5
	},
	dropzoneText: {
		color: grey[600]
	},
	adverPaper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: theme.spacing(1.5)
	},
	adverView: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1)
	},
	textarea: {
		width: '100%'
	},
	dateBtn: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	statusBtn: {
		marginLeft: 30,
		width: 150
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	},
	alignRight: {
		display: 'flex',
		marginBottom: 10,
		flex: 1,
		flexDirection: 'row'
	}
}));

export default function RStockTake() {
	const tableHead = [
		{
			name: "st_id",
			label: "ID",
			options: {
				display: false
			}
		},
		{
			name: "checkin_date",
			label: "Check-In Date",
			options: {
				display: true,
				customBodyRender: (value) => moment(value, "DD/MM/YYYY").format('YYYY-MM-DD')
			}
		},
		{
			name: "cust_code",
			label: "Outlet Code",
			options: {
				display: false
			}
		},
		{
			name: "cust_company_name",
			label: "Outlet",
			options: {
				display: true
			}
		},
		{
			name: "cust_category_name",
			label: "Outlet Category",
			options: {
				display: false
			}
		},
		{
			name: "cust_chain_name",
			label: "Outlet Chain",
			options: {
				display: false
			}
		},
		{
			name: "name",
			label: "Agent Name",
			options: {
				display: true
			}
		},
		{
			name: "cust_area",
			label: "Outlet Area",
			options: {
				display: false
			}
		},
		// {
		// 	name: "company",
		// 	label: "Branch",

		// 	options: {
		// 		display: false,
		// 		customBodyRender: (value) => value ? value.toUpperCase() : ''
		// 	}
		// },
		{
			name: "product_code",
			label: "Item Code",
			options: {
				display: false
			}
		},
		{
			name: "product_name",
			label: "Item Name",
			options: {
				display: true
			}
		},
		{
			name: "product_barcode",
			label: "Product Barcode",
			options: {
				display: false
			}
		},
		{
			name: "current_quantity",
			label: "Current QTY",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					switch(value) {
						case '1888888':
							return 'High';
						case '1555555':
							return 'Medium';
						case '1111111':
							return 'Low';
						case '-111111':
							return 'Delist';
						default:
							return value;
					}
				}
			}
		},
		{
			name: "suggested_quantity",
			label: "Proposed QTY",
			options: {
				display: false
			}
		},
		{
			name: "delist",
			label: "Delist",
			options: {
				display: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					if (value === '-111111') {
						return 'Yes';
					}
					return '';
				}
			}
		},
		{
			name: "unit_uom",
			label: "UOM",
			options: {
				display: true
			}
		},
		{
			name: "uom_barcode",
			label: "UOM Barcode",
			options: {
				display: false
			}
		},
		{
			name: "expiry_date",
			label: "Expiry Date",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					if (moment(value, 'YYYY-MM-DD HH:mm:ss').format("YYYY") === "1970"){
						return "-";
					}
					return (
						moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
					)
				}
			}
		},
		{
			name: "suggest_return",
			label: "Return QTY",
			options: {
				display: false
			}
		},
		{
			name: "suggest_transfer",
			label: "Transfer QTY",
			options: {
				display: false
			}
		},
		{
			name: "prod_category_name",
			label: "Product Category",
			options: {
				display: false
			}
		},
		{
			name: "prod_brand_name",
			label: "Product Brand",
			options: {
				display: false
			}
		},
		{
			name: "rsp",
			label: "RSP",
			options: {
				display: true
			}
		},
		{
			name: "sos",
			label: "SOS",
			options: {
				display: true
			}
		},
		{
			name: "shelf_level",
			label: "Shelf Level",
			options: {
				display: true
			}
		},
		{
			name: "sp_remark",
			label: "Remark",
			options: {
				display: true
			}
		}
	];
	const commonStyle = useCommonStyles();
	const classes = useStyles();
	const singleton = PersistantData.getInstance();
	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}
	const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();

	const [splist, setSPlist] = useState([]);
	const [itemList, setItemList] = useState([]);
	const [outletList, setOutletList] = useState([]);
	const [stList, setStList] = useState([]);

	const [stockTakeDateRange, setStockTakeDateRange] = useState({});
	const [openStockTakeDateDoc, setOpenStockTakeDateDoc] = useState(false);
	const [stockTakeSpView, setStockTakeSpView] = useState(false);
	const [stockTakeOutletView, setStockTakeOutletView] = useState(false);
	const [stockTakeOutlet, setStockTakeOutlet] = useState(empty);
	const [stockTakeOutletSelect, setStockTakeOutletSelected] = useState(empty);
	const [stockTakeSpSelect, setStockTakeSpSelected] = useState(empty);
	const [stockTakeSalesperson, setStockTakeSalesperson] = useState(empty);
	const [stockTakeProductView, setStockTakeProductView] = useState(false);
	const [stockTakeProduct, setStockTakeProduct] = useState(empty);
	const [stockTakeProductSelect, setStockTakeProductSelected] = useState(empty);
	const [status, setStatus] = useState(1);
	const [itemStatusAnchor, setItemStatusAnchor] = useState(null);
	const [config, setConfig] = useState(0);
	const [printView, setPrintView] = useState(false);
	const [tableHeader, setTableHeader] = useState([]);
	const [areaList, setAreaList] = useState([]);
	const [areaView, setAreaView] = useState(false);
	const [selectedArea, setSelectedArea] = useState(empty);

	const [customerCategoryList, setCustomerCategoryList] = useState([]);
	const [customerChainList, setCustomerChainList] = useState([]);
	const [productCategoryList, setProductCategoryList] = useState([]);
	const [productBrandList, setProductBrandList] = useState([]);

	const [selectedCustomerCategory, setSelectedCustomerCategory] = useState(empty);
	const [selectedCustomerChain, setSelectedCustomerChain] = useState(empty);
	const [selectedProductCategory, setSelectedProductCategory] = useState(empty);
	const [selectedProductBrand, setSelectedProductBrand] = useState(empty);

	const [selectedCustomerCategoryValue, setSelectedCustomerCategoryValue] = useState(empty);
	const [selectedCustomerChainValue, setSelectedCustomerChainValue] = useState(empty);
	const [selectedProductCategoryValue, setSelectedProductCategoryValue] = useState(empty);
	const [selectedProductBrandValue, setSelectedProductBrandValue] = useState(empty);

	const [customerCategoryView, setCustomerCategoryView] = useState(false);
	const [customerChainView, setCustomerChainView] = useState(false);
	const [productCategoryView, setProductCategoryView] = useState(false);
	const [productBrandView, setProductBrandView] = useState(false);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
			getOutletList(gState.client);
			loadStocksList(gState.client);
			loadConfig(gState.client);
			loadCustCategoryList(gState.client);
			loadCustChainList(gState.client);
			loadProdCategoryList(gState.client);
			loadBrand(gState.client);
			getAreaList(gState.client);
		}
		setTableHeader(tableHead);
	}, [gState.client, gState.translation]);

	const loadConfig = (_client = client) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _config = getSetting(extraData, 'customization', {});
				if(_config.rstocktake){
					setConfig(_config.rstocktake);
				}

				const _modules = getSetting(extraData, 'modules', {});
				if (_modules['branch'] == true) {
					tableHead.splice(8, 0, 	{
						name: "company",
						label: "Branch",
			
						options: {
							display: false,
							customBodyRender: (value) => value ? value.toUpperCase() : ''
						}
					});
				}	
			}
		});
		API_Service.get(req_mobile_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const {module, pdf_images} = extraData;
				const _st_notes = module.find((content) => content.module === 'app_stock_take_notes');
				if (_st_notes) {
					const {status} = _st_notes;
					let setting = JSON.parse(status);
					if (setting) {
						tableHead.splice(8, 0, {
							name: "top_up_qty",
							label: "Top Up QTY",
							options: {
								display: true
							}
						});
						tableHead.push({
							name: "note",
							label: "Note",
							options: {
								display: true
							}
						});
					}
				}
				// const _customization = module.find((content) => content.module === 'app_customization');
				// if (_customization) {
				// 	const {status} = _customization;
				// 	var customize_module = JSON.parse(status);

				// 	if (customize_module.hasOwnProperty('stockreturn') && customize_module.stockreturn) {
				// 		tableHead.splice(8, 0, {
				// 			name: "return_qty",
				// 			label: "Return Quantity",
				// 			options: {
				// 				display: true
				// 			}
				// 		});
				// 	}
				// }
			}
		});
	}

	const loadCustCategoryList = (_client = client) => {
		API_Service.get(req_cust_category, axiosDefaultConfig(_client)).then(({data}) => {
			if (data) {
				var {result, extraData } = data;
				if (parseInt(result) === 1) {
					var _arr = [];
					for (let i = 0; i < extraData.length; i++) {
						const {cust_category_id, cust_category_code, cust_category_name, cust_category_status} = extraData[i];
						if (parseInt(cust_category_status) === 1) {
							_arr.push({
								name: cust_category_name,
								secondary: cust_category_code,
								value: cust_category_id,
								status: 1
							});
						} else {
							_arr.push({
								name: cust_category_name,
								secondary: cust_category_code,
								value: cust_category_id,
								status: 0
							});
						}
					}
					setCustomerCategoryList(() => (_arr));
				}
			}
		}).catch(err => de(err.message, 'customer category list'));
	}

	const loadCustChainList = (_client = client) => {
		API_Service.get(req_cust_chain, axiosDefaultConfig(_client)).then(({data}) => {
			if (data) {
				var {result, extraData } = data;
				if (parseInt(result) === 1) {
					var _arr = [];
					for (let i = 0; i < extraData.length; i++) {
						const {cust_chain_id, cust_chain_code, cust_chain_name, cust_chain_status} = extraData[i];
						if (parseInt(cust_chain_status) === 1) {
							_arr.push({
								name: cust_chain_name,
								secondary: cust_chain_code,
								value: cust_chain_id,
								status: 1
							});
						}else{
							_arr.push({
								name: cust_chain_name,
								secondary: cust_chain_code,
								value: cust_chain_id,
								status: 0
							});
						}
					}
					setCustomerChainList(() => (_arr));
				}
			}
		}).catch(err => de(err.message, 'customer chain list'));
	}

	const loadProdCategoryList = (_client = client) => {
		API_Service.get(req_stock_category, axiosDefaultConfig(_client)).then(({data}) => {
			if (!_.isEmpty(data)) {
				var _arr = [];
				for (let idx = 0; idx < data.length; idx++) {
					const {category_id, categoryIdentifierId, category_name, category_status} = data[idx];
					if (parseInt(category_status) === 1) {
						_arr.push({
							name: category_name,
							secondary: categoryIdentifierId,
							value: category_id,
							status: 1
						});
					} else {
						_arr.push({
							name: category_name,
							secondary: categoryIdentifierId,
							value: category_id,
							status: 0
						});
					}
				}
				setProductCategoryList(() => (_arr));
			} else {

			}
		});
	}

	const loadBrand = (_client = client) => {
		API_Service.get(req_stock_brand, axiosDefaultConfig(_client)).then(({data}) => {
			var _arr = [];
			if (!_.isEmpty(data)) {
				for (let idx = 0; idx < data.length; idx++) {
					const {brand_id, brand_code, brand_name, brand_status} = data[idx];
					if (parseInt(brand_status) === 1) {
						_arr.push({
							name: brand_name,
							secondary: brand_code,
							value: brand_id,
							status: 1
						});
					} else {
						_arr.push({
							name: brand_name,
							secondary: brand_code,
							value: brand_id,
							status: 0
						});
					}
				}
			}
			setProductBrandList(() => (_arr));
		});
	}

	const getAreaList = (_client = client) => {
		API_Service.get(req_cust_area, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {area_name, area_code, area_status} = extraData[idx];
					_data.push({name: area_name, secondary: area_code, value: area_code, status: parseInt(area_status)});
				}
				setAreaList(_data);
			}
		});
	}

	const AreaViewClick = () => {
		setAreaView(true);
	}

	const AreaViewClose = (newValue) => {
		setAreaView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setSelectedArea(value);
		}
	}

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const getStockTake = (_client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(stockTakeDateRange)) {
			st_date = format(stockTakeDateRange.startDate, defDateFormat);
			st_date_to = format(stockTakeDateRange.endDate, defDateFormat);
		}
		var parameters = {
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: stockTakeSpSelect,
			cust_code: stockTakeOutletSelect,
			cust_category_id: selectedCustomerCategoryValue,
			cust_chain_id: selectedCustomerChainValue,
			product_code: stockTakeProductSelect,
			prod_category_id: selectedProductCategoryValue,
			prod_brand_id: selectedProductBrandValue,
			status: status,
			area: selectedArea,
			username: cookies(ck_u_username),
		};
		if (gState.company) {
			parameters.company = gState.company;
		}
		const param = axiosURIencode(parameters);
		API_Service.get(`${req_report_stocktake}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				const _date_format_mysql = "YYYY-MM-DD HH:mm:ss";
				for (let idx = 0; idx < extraData.length; idx++) {
					var _arr = extraData[idx];
					if (_arr['checkin_time']) {
						_arr['checkin_date'] = moment(_arr['checkin_time'],_date_format_mysql).format('DD/MM/YYYY');
						_arr['checkin_time'] = moment(_arr['checkin_time'],_date_format_mysql).format('hh:mmA');
					}
					if (_arr['checkout_time']) {
						_arr['checkout_date'] = moment(_arr['checkout_time'],_date_format_mysql).format('DD/MM/YYYY');
						_arr['checkout_time'] = moment(_arr['checkout_time'],_date_format_mysql).format('hh:mmA');
					}
					_arr['created_date'] = moment(_arr['created_date'],_date_format_mysql).format('DD/MM/YYYY');
					_data.push(_arr);
				}
				setStList(stList => (_data));
			} else {
				setStList(stList => ([]));
				snack(language.no_data, 'warning');
			}
		});
	}

	const getSalesmanList = (_client = client) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_salesman}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login, login_status} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
				}
				setSPlist(splist => (_data));
			}
		});
	}

	const getOutletList = (_client = client) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_cust}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 1
					});
				}
				for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 0
					});
				}
				setOutletList(arr);
			}
		});
	}

	const loadStocksList = (_client = client) => {
		var req = `${req_stock}-1`;
		API_Service.get(req, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			const {stock: stockApi} = extraData;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < stockApi.length; idx++) {
					const {product_name, product_code, product_status} = stockApi[idx];
					_data.push({name: product_name, secondary: product_code, value: product_code, status: parseInt(product_status)});
				}
				setItemList(itemList => (_data));
			}
		});
	}

	const StockTakeSPViewClick = () => {
		setStockTakeSpView(true);
	}

	const StockTakeSPViewClose = (newValue) => {
		setStockTakeSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setStockTakeSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setStockTakeSalesperson(spname);
		}
	}

	const StockTakeOutletViewClick = () => {
		setStockTakeOutletView(true);
	}

	const StockTakeOutletViewClose = (newValue) => {
		setStockTakeOutletView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setStockTakeOutletSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setStockTakeOutlet(spname);
		}
	}

	const StockTakeProductViewClick = () => {
		setStockTakeProductView(true);
	}

	const StockTakeProductViewClose = (newValue) => {
		setStockTakeProductView(false);
		if (newValue) {
			var itemsvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					itemsvalue += newValue[i].value;
				} else {
					itemsvalue += newValue[i].value + ",";
				}
			}
			setStockTakeProductSelected(itemsvalue);

			var itemsname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					itemsname += newValue[i].name;
				} else {
					itemsname += newValue[i].name + ",";
				}
			}
			setStockTakeProduct(itemsname);
		}
	}

	const OnCloseCustomerCategoryView = (data) => {
		setCustomerCategoryView(false);
		if (data) {
			var itemsvalue = '';
			for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					itemsvalue += data[i].value;
				} else {
					itemsvalue += data[i].value + ",";
				}
			}
			setSelectedCustomerCategoryValue(itemsvalue);

			var itemsname = '';
			for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					itemsname += data[i].name;
				} else {
					itemsname += data[i].name + ",";
				}
			}
			setSelectedCustomerCategory(itemsname);
		}
	}

	const OnCloseCustomerChainView = (data) => {
		setCustomerChainView(false);
		if (data) {
			var itemsvalue = '';
			for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					itemsvalue += data[i].value;
				} else {
					itemsvalue += data[i].value + ",";
				}
			}
			setSelectedCustomerChainValue(itemsvalue);

			var itemsname = '';
			for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					itemsname += data[i].name;
				} else {
					itemsname += data[i].name + ",";
				}
			}
			setSelectedCustomerChain(itemsname);
		}
	}

	const OnCloseProductCategoryView = (data) => {
		setProductCategoryView(false);
		if (data) {
			var itemsvalue = '';
			for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					itemsvalue += data[i].value;
				} else {
					itemsvalue += data[i].value + ",";
				}
			}
			setSelectedProductCategoryValue(itemsvalue);

			var itemsname = '';
			for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					itemsname += data[i].name;
				} else {
					itemsname += data[i].name + ",";
				}
			}
			setSelectedProductCategory(itemsname);
		}
	}

	const OnCloseProductBrandView = (data) => {
		setProductBrandView(false);
		if (data) {
			var itemsvalue = '';
			for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					itemsvalue += data[i].value;
				} else {
					itemsvalue += data[i].value + ",";
				}
			}
			setSelectedProductBrandValue(itemsvalue);

			var itemsname = '';
			for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					itemsname += data[i].name;
				} else {
					itemsname += data[i].name + ",";
				}
			}
			setSelectedProductBrand(itemsname);
		}
	}

	const getStockTakeDate = () => {
		return !_.isEmpty(stockTakeDateRange) ? moment(stockTakeDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(stockTakeDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

	const openPrintSelectView = () => {
		setPrintView(true);
	}

	const printViewClose = (type) => {
		generateStockTakeReport(type);
		setPrintView(false);
	}

	const generateStockTakeReport = (type, _client = client) => {
		if(type == 'simply'){
			var st_date = '';
			var st_date_to = '';
			if (!_.isEmpty(stockTakeDateRange)) {
				st_date = format(stockTakeDateRange.startDate, defDateFormat);
				st_date_to = format(stockTakeDateRange.endDate, defDateFormat);
			}
			const date_range = `${st_date} to ${st_date_to}`;
			const branch = gState.company ? gState.company : '';
			const json = JSON.stringify(tableHeader);
			var link = JString(stock_take_report_url)
				.replaceInfinity('@client', client)
				.replaceInfinity('@reportDate', encodeURIComponent(date_range))
				.replaceInfinity('@salespersonId', stockTakeSpSelect)
				.replaceInfinity('@cust_code', stockTakeOutletSelect)
				.replaceInfinity('@product_code', stockTakeProductSelect)
				// .replaceInfinity('@status', status)
				.replaceInfinity('@cust_category_id', selectedCustomerCategoryValue)
				.replaceInfinity('@cust_chain_id', selectedCustomerChainValue)
				.replaceInfinity('@prod_category_id', selectedProductCategoryValue)
				.replaceInfinity('@prod_brand_id', selectedProductBrandValue)
				.replaceInfinity('@branch', branch)
				.replaceInfinity('@json', json)
				.replaceInfinity('@username', cookies(ck_u_username))
				.str;
			window.open(link, "_blank");
		}
		if(type == 'normal'){
			var st_date = '';
			var st_date_to = '';
			if (!_.isEmpty(stockTakeDateRange)) {
				st_date = format(stockTakeDateRange.startDate, defDateFormat);
				st_date_to = format(stockTakeDateRange.endDate, defDateFormat);
			}
			var parameters = {
				client: client,
				date_from: st_date,
				date_to: st_date_to,
				salespersonId: stockTakeSpSelect,
				cust_code: stockTakeOutletSelect,
				product_code: stockTakeProductSelect,
				cust_category_id: selectedCustomerCategoryValue,
				cust_chain_id: selectedCustomerChainValue,
				prod_category_id: selectedProductCategoryValue,
				prod_brand_id: selectedProductBrandValue,
				status: status,
				username: cookies(ck_u_username),
			};
			if (gState.company) {
				parameters.company = gState.company;
			}
			const param = axiosURIencode(parameters);
			API_Service.get(`${req_stocktake_report}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
				const {result, extraData} = data;
				if (result) {
					window.open(extraData.url, '_blank');
					const delay = setTimeout(() => {
						API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosDefaultConfig(_client));
						clearTimeout(delay);
					}, 5000);
				} else {
					snack(language.no_data, 'warning');
				}
			}).catch(err => {
				snack('The data is too large, please simplify the requirements', 'warning');
			});
		}

	}

	return (
		<div>
			{/* <Typography className={classes.heading}>{ language.generate_stock_take_report }</Typography> */}
			<div className={classes.viewInRow}>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => StockTakeSPViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{stockTakeSalesperson ? _.truncate(stockTakeSalesperson, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => StockTakeOutletViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{stockTakeOutlet ? _.truncate(stockTakeOutlet, {length: 20}) : language.customer}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setCustomerCategoryView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedCustomerCategory ? _.truncate(selectedCustomerCategory, {length: 20}) : "Outlet Category"}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setCustomerChainView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedCustomerChain ? _.truncate(selectedCustomerChain, {length: 20}) : "Outlet Chain"}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => AreaViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => StockTakeProductViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{stockTakeProduct ? _.truncate(stockTakeProduct, {length: 20}) : language.items}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setProductCategoryView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedProductCategory ? _.truncate(selectedProductCategory, {length: 20}) : "Item Category"}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setProductBrandView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedProductBrand ? _.truncate(selectedProductBrand, {length: 20}) : "Item Brand"}
					</Typography>
				</Button>
				{/* <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					aria-controls="active-status-menu"
					aria-haspopup="true"
					onClick={event=>setItemStatusAnchor(event.currentTarget)}>
					{status === 1 ? language.active : language.inactive}
				</Button>
				<Menu
					id="active-status-menu"
					anchorEl={itemStatusAnchor}
					keepMounted
					open={Boolean(itemStatusAnchor)}
					onClose={()=>setItemStatusAnchor(null)}>
					<MenuItem style={{width: 300}} onClick={()=>{setItemStatusAnchor(null); setStatus(1);}}>{language.active}</MenuItem>
					<MenuItem style={{width: 300}} onClick={()=>{setItemStatusAnchor(null); setStatus(0);}}>{language.inactive}</MenuItem>
				</Menu> */}
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setOpenStockTakeDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getStockTakeDate()}
					</Typography>
				</Button>
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getStockTake()}>
					<Typography className={commonStyle.searchBtnFont}>
						{language.view}
					</Typography>
				</Button>
			</div>
			<div style={{height: 20}}/>
			{
				stList.length > 0 && (
					<MUIDataTable
						title={language.stock_take}
						data={stList}
						columns={config ? tableHeader.map(obj => {
							if(obj.name == "suggested_quantity"){
								obj.label = "Stock Transfer";
							}
							return obj;
						}) : tableHeader}
						options={{
							search: false,
							print: false,
							filter: false,
							download: false,
							selectableRows: 'none',
							rowsPerPage: 25,
							rowsPerPageOptions: [25, 40, 65, 100],
							responsive: 'simple',
							onColumnViewChange: (changedColumn, action) => {
								if(tableHeader.some(item => changedColumn === item.name)){
									var index = tableHeader.findIndex(item => changedColumn === item.name);
								}
								switch(action){
									case 'add':
										tableHeader[index].options.display = true;
										break;
									case 'remove':
										tableHeader[index].options.display = false;
										break;
								}
								setTableHeader(tableHeader);
							},
							customToolbar: () =>
								<>
									<CheckinTableReportButton onGenerateReport={openPrintSelectView}/>
								</>
						}}
					/>
				)
			}

			<VirtualListCB
				open={stockTakeSpView}
				setClose={data => StockTakeSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={stockTakeOutletView}
				setClose={data => StockTakeOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={stockTakeProductView}
				setClose={data => StockTakeProductViewClose(data)}
				title={"Product"}
				data={itemList}
				language={language}
				filteration={1}
			/>
			
			<VirtualListCB
				open={areaView}
				setClose={data => AreaViewClose(data)}
				title={"Area"}
				data={areaList}
				language={language}
				filteration={1}
			/>
			
			<VirtualListCB
				open={customerCategoryView}
				setClose={data => OnCloseCustomerCategoryView(data)}
				title={"Outlet Category"}
				data={customerCategoryList}
				language={language}
				filteration={1}
			/>
			<VirtualListCB
				open={customerChainView}
				setClose={data => OnCloseCustomerChainView(data)}
				title={"Outlet Chain"}
				data={customerChainList}
				language={language}
				filteration={1}
			/>
			<VirtualListCB
				open={productCategoryView}
				setClose={data => OnCloseProductCategoryView(data)}
				title={"Product Category"}
				data={productCategoryList}
				language={language}
				filteration={1}
			/>
			<VirtualListCB
				open={productBrandView}
				setClose={data => OnCloseProductBrandView(data)}
				title={"Product Brand"}
				data={productBrandList}
				language={language}
				filteration={1}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setStockTakeDateRange(range);
					setOpenStockTakeDateDoc(false)
				}}
				setNegative={() => {
					setOpenStockTakeDateDoc(false)
				}}
				setClose={() => {
					setOpenStockTakeDateDoc(false)
				}}
				open={openStockTakeDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>

			<GenerateView
				classes={{
					paper: commonStyle.listView,
				}}
				id="ringtone-menu"
				keepMounted
				open={printView}
				onClose={() => setPrintView(false)}
				onSelect={printViewClose}
				value={'simply'}
				title={language.generate_type || ''}
				language={language}
				options={[
					{name: 'Basic Version', value: 'simply'},
					{name: 'Detailed Version', value: 'normal'}
				]}
			/>
		</div>
	)
}

function CheckinTableReportButton({onGenerateReport}) {
	return (
		<React.Fragment>
			<Tooltip title={'Generate Report'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => onGenerateReport()} aria-label="addnew">
					<AssessmentIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}
