import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import Button from '@material-ui/core/Button';
import {useCommonStyles} from '../../Styles';
import {
	defDateFormat,
	empty,
	req_cust,
	req_cust_chain,
	req_salesman,
	req_delete_file,
	req_report_stocknearexpiryanalysisbyday,
	req_report_stocknearexpiryanalysisreportbyday,
	req_stock_def,
	req_cust_category
} from '../../Helper/Constants';
import _ from 'lodash';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import {useSnackbar} from 'notistack';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import moment from 'moment';
import VirtualListCB from '../../Components/VirtualListCB';
import format from 'date-fns/format';
import {de} from '../../Helper/Helper';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, CircularProgress, TextField, InputAdornment } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	},
	container: {
		maxHeight: '78vh',
		maxWidth: '92vw',
	},
	boldText: {
		fontWeight: 'bold',
	},
	tableHeadRow: {
		backgroundColor: '#424242',
		fontSize: 10,
		padding: 0,
		margin: 0
	},
    tableHeadRow1: {
		backgroundColor: '#424242',
		fontSize: 10,
		padding: 0,
		margin: 0,
        width: '1%'
	},
	tableCellAutoWidth: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		fontSize: 12,
		padding: 0,
		margin: 0
	},
	table : {
		fontSize: 12,
		borderCollapse: 'separate',
		borderSpacing: '4px 0px'
	},
	customDatePicker: {
		"& .MuiOutlinedInput-root" : {
			height: "100%",
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			padding: "5px 15px",
			fontSize: 12,
			textAlign: "center",
		},
		width: 300,
		marginRight: 10,
		fontSize: 12,
	},
}));

export default function RStockNearExpiryAnalysisByDay() {
	const classes = useStyles();
	const commonStyle = useCommonStyles();
	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	const singleton = PersistantData.getInstance();
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const [splist, setSPlist] = useState([]);
	const [outletList, setOutletList] = useState([]);
	const [outletCategoryList, setOutletCategoryList] = useState([]);
	const [outletChainList, setOutletChainList] = useState([]);
	const [itemList, setItemList] = useState([]);

	const [spView, setSpView] = useState(false);
	const [spSelectedValue, setSpSelectedValue] = useState(empty);
	const [spSelectedName, setSpSelectedName] = useState(empty);

	const [outletView, setOutletView] = useState(false);
	const [outletSelectedName, setOutletSelectedName] = useState(empty);
	const [outletSelectedValue, setOutletSelectedValue] = useState(empty);

	const [categoryListView, setCategoryListView] = useState(false);
	const [categorySelectedName, setCategorySelectedName] = useState(empty);
	const [categorySelectedValue, setCategorySelectedValue] = useState(empty);

	const [chainListView, setChainListView] = useState(false);
	const [chainSelectedName, setChainSelectedName] = useState(empty);
	const [chainSelectedValue, setChainSelectedValue] = useState(empty);

	const [productListView, setProductListView] = useState(false);
	const [productSelectedName, setProductSelectedName] = useState(empty);
	const [productSelectedValue, setProductSelectedValue] = useState(empty);

	const [calculationDate, setCalculationDate] = useState(new Date());
	const [outletVisitDateRange, setOutletVisitDateRange] = useState({});
	const [openOutletVisitDateDoc, setOpenOutletVisitDateDoc] = useState(false);

	const [loading, setLoading] = useState(false);
	const [reportData, setreportData] = useState([]);
	const [totals, setTotals] = useState([]);

	const getOutletVisitDate = () => {
		return !_.isEmpty(outletVisitDateRange) ? moment(outletVisitDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(outletVisitDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
			getOutletList(gState.client);
			getOutletCategory(gState.client);
			getOutletChain(gState.client);
			loadProductList(gState.client);
		}
	}, [gState.client]);

	const getSalesmanList = (_client = client) => {
		API_Service.get(req_salesman, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id});
				}
				setSPlist(splist => (_data));
			} else {
				snack(gState.translation.not_found_salesperson, 'warning');
			}
		});
	}

	const getOutletList = (_client = client) => {
		API_Service.get(req_cust, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_id, cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_id,
						status: 1
					});
				}
				for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_id, cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_id,
						status: 0
					});
				}
				setOutletList(arr);
			}
		});
	}

	const getOutletCategory = (_client = client) => {
		API_Service.get(req_cust_category, axiosDefaultConfig(_client)).then(({data}) => {
			if (data) {
				var {result, extraData } = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var _data = [];
					for (let i = 0; i < extraData.length; i++) {
						const {cust_category_name, cust_category_code, cust_category_id, cust_category_status} = extraData[i];
						if (parseInt(cust_category_status) === 1) {
							_data.push({
								name: cust_category_name,
								secondary: cust_category_code,
								value: cust_category_id,
								status: 1
							});
						} else {
							_data.push({
								name: cust_category_name,
								secondary: cust_category_code,
								value: cust_category_id,
								status: 0
							});
						}
					}
					setOutletCategoryList(()=>(_data));
				}
			}
		}).catch(err => de(err.message, 'customer category list'));
	}

	const getOutletChain = (_client = client) => {
		API_Service.get(req_cust_chain, axiosDefaultConfig(_client)).then(({data}) => {
			if (data) {
				var {result, extraData } = data;

				result = JString(result).toNumber();
				if (result === 1) {
					var _data = [];
					for (let i = 0; i < extraData.length; i++) {
						const {cust_chain_name, cust_chain_code, cust_chain_id, cust_chain_status} = extraData[i];
						if (parseInt(cust_chain_status) === 1) {
							_data.push({
								name: cust_chain_name,
								secondary: cust_chain_code,
								value: cust_chain_id,
								status: 1
							});
						} else {
							_data.push({
								name: cust_chain_name,
								secondary: cust_chain_code,
								value: cust_chain_id,
								status: 0
							});
						}
					}
					setOutletChainList(()=>(_data));
				}
			}
		}).catch(err => de(err.message, 'customer category list'));
	}

	const loadProductList = (_client = client) => {
		API_Service.get(req_stock_def, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			const {stock: stockApi} = extraData;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < stockApi.length; idx++) {
					const {product_name, product_code, product_status} = stockApi[idx];
					_data.push({name: product_name, secondary: product_code, value: product_code, status: parseInt(product_status)});
				}
				setItemList(itemList => (_data));
			}
		});
	}

	const OutletVisitSPViewClose = (newValue) => {
		setSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setSpSelectedValue(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setSpSelectedName(spname);
		}
	}

	const OutletVisitOutletViewClose = (newValue) => {
		setOutletView(false);
		if (newValue) {
			var custvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custvalue += newValue[i].value;
				} else {
					custvalue += newValue[i].value + ",";
				}
			}
			setOutletSelectedValue(custvalue);

			var custname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custname += newValue[i].name;
				} else {
					custname += newValue[i].name + ",";
				}
			}
			setOutletSelectedName(custname);
		}
	}

	const CategoryViewClose = (newValue) => {
		setCategoryListView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setCategorySelectedValue(value);

			var name = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					name += newValue[i].name;
				} else {
					name += newValue[i].name + ",";
				}
			}
			setCategorySelectedName(name);
		}
	}

	const ChainViewClose = (newValue) => {
		setChainListView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setChainSelectedValue(value);

			var name = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					name += newValue[i].name;
				} else {
					name += newValue[i].name + ",";
				}
			}
			setChainSelectedName(name);
		}
	}

	const ProductViewClose = (newValue) => {
		setProductListView(false);
		if (newValue) {
			var itemsvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					itemsvalue += newValue[i].value;
				} else {
					itemsvalue += newValue[i].value + ",";
				}
			}
			setProductSelectedValue(itemsvalue);

			var itemsname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					itemsname += newValue[i].name;
				} else {
					itemsname += newValue[i].name + ",";
				}
			}
			setProductSelectedName(itemsname);
		}
	}

	const getReportData = (_client = client) => {
		setLoading(true);
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(outletVisitDateRange)) {
			st_date = format(outletVisitDateRange.startDate, defDateFormat);
			st_date_to = format(outletVisitDateRange.endDate, defDateFormat);
		}
		var parameters = {
			date_from: st_date,
			date_to: st_date_to,
			calculation_date: moment(calculationDate).format('YYYY-MM-DD'),
			salespersonId: spSelectedValue,
			cust_code: outletSelectedValue,
			cust_category_id: categorySelectedValue,
			cust_chain_id: chainSelectedValue,
			product_code: productSelectedValue
		};
		const param = axiosURIencode(parameters);

		API_Service.get(`${req_report_stocknearexpiryanalysisbyday}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const {data, total} = extraData;
				setreportData(data);
				setTotals(total);
			} else {
				snack(language.no_data, 'warning');
			}
			setLoading(false);
		});
	}

	const onGenerateReport = (_client = client) => {
		setLoading(true);
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(outletVisitDateRange)) {
			st_date = format(outletVisitDateRange.startDate, defDateFormat);
			st_date_to = format(outletVisitDateRange.endDate, defDateFormat);
		}
		var parameters = {
			date_from: st_date,
			date_to: st_date_to,
			calculation_date: moment(calculationDate).format('YYYY-MM-DD'),
			salespersonId: spSelectedValue,
			cust_code: outletSelectedValue,
			cust_category_id: categorySelectedValue,
			cust_chain_id: chainSelectedValue,
			product_code: productSelectedValue
		};
		const param = axiosURIencode(parameters);

		API_Service.get(`${req_report_stocknearexpiryanalysisreportbyday}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				window.open(extraData.url, '_blank');
				const delay = setTimeout(() => {
					API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosDefaultConfig(_client));
					clearTimeout(delay);
				}, 5000);
			} else {
				snack(language.no_data, 'warning');
			}
			setLoading(false);
		});
	}

	return (
		<div>
			<div className={classes.viewInRow}>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setSpView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{spSelectedName ? _.truncate(spSelectedName, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setOutletView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{outletSelectedName ? _.truncate(outletSelectedName, {length: 20}) : language.customer}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setCategoryListView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{categorySelectedName ? _.truncate(categorySelectedName, {length: 20}) : language.category}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setChainListView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{chainSelectedName ? _.truncate(chainSelectedName, {length: 20}) : language.chain}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setProductListView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{productSelectedName ? _.truncate(productSelectedName, {length: 20}) : language.items}
					</Typography>
				</Button>
			</div>
			<div className={classes.viewInRow}>
				<Typography className={commonStyle.searchBtnFont} style={{alignContent: 'center', marginRight: 10}}>Expiry Count Date: </Typography>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<DatePicker
						className={classes.customDatePicker}
						inputVariant={"outlined"}
						format="dd MMMM yyyy"
						value={calculationDate}
						onChange={setCalculationDate}
						renderInput={(params) => (
							<TextField {...params} InputProps={{
								endAdornment:
									<InputAdornment position="end">
										<InsertInvitationIcon />
									</InputAdornment>
							}} />
						)}
					/>
				</MuiPickersUtilsProvider>
				<Typography className={commonStyle.searchBtnFont} style={{alignContent: 'center', marginRight: 10}}>Stock Take Date: </Typography>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setOpenOutletVisitDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getOutletVisitDate()}
					</Typography>
				</Button>
				<Button
					style={{marginRight: 10}}
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getReportData()}
					disabled={loading ? true : false}>
					<Typography className={commonStyle.searchBtnFont}>{language.view}</Typography>
					{
						loading ? <CircularProgress size={20}/> : ''
					}
				</Button>
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => onGenerateReport()}
					disabled={loading ? true : false}>
					<Typography className={commonStyle.searchBtnFont}>Generate Report</Typography>
					{
						loading ? <CircularProgress size={20}/> : ''
					}
				</Button>
			</div>
			<div style={{height: 20}}/>
			{
				reportData.length > 0 && (
					<TableContainer component={Paper} className={classes.container}>
						<Table stickyHeader cellSpacing="0" cellPadding="0" sx={{ minWidth: 650 }} size="small" className={classes.table} aria-label="spanning table">
							<TableHead>
								<TableRow>
									<TableCell className={classes.tableHeadRow} align="left" colSpan={15}><Typography component="h6" className={classes.boldText}>Stock Near Expiry Analysis (By Day)</Typography></TableCell>
									<TableCell className={classes.tableHeadRow} align="left" colSpan={2}><React.Fragment></React.Fragment></TableCell>
								</TableRow>
								<TableRow>
									<TableCell className={classes.tableHeadRow} align="left">No</TableCell>
									<TableCell className={classes.tableHeadRow} colSpan={4} align="left" style={{width:100}}>Customer</TableCell>
									<TableCell className={classes.tableHeadRow} align="left">Category</TableCell>
									<TableCell className={classes.tableHeadRow} align="left">Date</TableCell>
									<TableCell className={classes.tableHeadRow} align="left">Agent</TableCell>
									<TableCell className={classes.tableHeadRow} align="left">Product</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">0</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">1</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">2</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">3</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">4</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">5</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">6</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">7</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">8</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">9</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">10</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">11</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">12</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">13</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">14</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">15</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">16</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">17</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">18</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">19</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">20</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">21</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">22</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">23</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">24</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">25</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">26</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">27</TableCell>
									<TableCell className={classes.tableHeadRow1} align="left">28</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">29</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">30</TableCell>
                                    <TableCell className={classes.tableHeadRow1} align="left">Total</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									reportData.map((data, index) => (
										<ExpandableTable index={index} classes={classes} data={data}/>
									))
								}
								<TableRow style={{ flexShrink: 0, background: '#424242', padding: '10px', position: 'sticky', bottom: 0, zIndex: 1000 }}>
									<TableCell rowSpan={1} />
									<TableCell className={classes.tableCellAutoWidth} colSpan={8}>Grand Total</TableCell>
									{
										Array(31).fill(0).map((_, index) => {
											const data = totals[index] !== undefined ? totals[index] : '';
											return (<TableCell className={classes.tableCellAutoWidth} key={`footer-${index}`} align="left">{data}</TableCell>);
										})
									}
									<TableCell className={classes.tableCellAutoWidth} align="left">
										{
											Array(32).fill(0).map((_, index) => totals[index] || 0).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
										}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				)
			}

			<VirtualListCB
				open={spView}
				setClose={data => OutletVisitSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
			/>

			<VirtualListCB
				open={outletView}
				setClose={data => OutletVisitOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={categoryListView}
				setClose={data => CategoryViewClose(data)}
				title={"Outlet Category"}
				data={outletCategoryList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={chainListView}
				setClose={data => ChainViewClose(data)}
				title={"Outlet Chain"}
				data={outletChainList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={productListView}
				setClose={data => ProductViewClose(data)}
				title={"Product"}
				data={itemList}
				language={language}
				filteration={1}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setOutletVisitDateRange(range);
					setOpenOutletVisitDateDoc(false)
				}}
				setNegative={() => {
					setOpenOutletVisitDateDoc(false)
				}}
				setClose={() => {
					setOpenOutletVisitDateDoc(false)
				}}
				limit={6}
				open={openOutletVisitDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>
		</div>
	)
}

function MonthColumn({classes, column, data}) {
	const col_data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
	let total = 0;
	const cells = Array(31).fill().map((_, index) => (
		<TableCell key={`column-${index}`} className={classes.tableCellAutoWidth} colSpan={1} align="left"></TableCell>
	));

	for (let x = 0; x < data.length; x++) {
		const col = column[x];
		const da = data[x];

		if (col >= 0 && col <= 30) {
			col_data[col] += da;
			cells[col] = (
				<TableCell key={`column-${col}`} className={classes.tableCellAutoWidth} colSpan={1} align="left">{col_data[col] === 0 ? '' : col_data[col]}</TableCell>
			);
			total += da;
		}

		cells[32] = (
			<TableCell key="column-8" className={classes.tableCellAutoWidth} colSpan={1} align="left" >{total === 0 ? '' : total}</TableCell>
		);
	}

	return <>{cells}</>;
}

function ExpandableTable({index, classes, data}){
	const col_data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
	const [isShowing, show] = useState(false);
	data.details.forEach((detail) => {
		let total = 0;
		for (let x = 0; x < detail.quantity.length; x++) {
			const col = detail.expired_in[x];
			const da = detail.quantity[x];

			if (col >= 0 && col <= 30) {
				col_data[col] += da;
				total += da;
			}

			col_data[31] += total;
		}
	});

	const cells = Array(32).fill().map((_, index) => (
		<TableCell key={`column-${index}`} className={classes.tableCellAutoWidth} colSpan={1} align="left">{col_data[index] === 0 ? '' : col_data[index]}</TableCell>
	));

	return (
		<>
			<TableRow key={index} onClick={()=>show(!isShowing)}>
				<TableCell className={classes.tableCellAutoWidth} align="left">{ isShowing ? <RemoveIcon/> : <AddIcon/> }</TableCell>
				<TableCell className={classes.tableCellAutoWidth} colSpan={4} align="left">{data.cust_name}</TableCell>
				<TableCell className={classes.tableCellAutoWidth} align="left">{data.cust_category_name}</TableCell>
				<TableCell className={classes.tableCellAutoWidth} align="left">{moment(data.stock_take_date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}</TableCell>
				<TableCell className={classes.tableCellAutoWidth} align="left">{data.agent_name}</TableCell>
				<TableCell className={classes.tableCellAutoWidth} colSpan={1} align="left"></TableCell>
				<>{cells}</>
			</TableRow>
			{
				isShowing && data.details.map((detail, index2) => (
					<TableRow key={index+"-details-"+index2}>
						<TableCell className={classes.tableCellAutoWidth} colSpan={1} align="left"></TableCell>
						<TableCell className={classes.tableCellAutoWidth} colSpan={4} align="left"></TableCell>
						<TableCell className={classes.tableCellAutoWidth} colSpan={1} align="left"></TableCell>
						<TableCell className={classes.tableCellAutoWidth} colSpan={1} align="left"></TableCell>
						<TableCell className={classes.tableCellAutoWidth} colSpan={1} align="left"></TableCell>
						<TableCell className={classes.tableCellAutoWidth} align="left">{detail.product_name}</TableCell>
						<MonthColumn classes={classes} column={detail.expired_in} data={detail.quantity} />
					</TableRow>
				))
			}
		</>
	)
}